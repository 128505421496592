import { List, Skeleton, Divider, Button, Modal, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import './styles/styles.css';
import { Link } from 'react-router-dom';
import { messages } from '../../services/messages';

import moment from 'moment';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import openNotification from '../Notificaciones';
import { loginTypes } from '../../constants';
import { connect } from 'react-redux';
import { some } from 'lodash';
import useSocket from '../../hooks/useSocket';
const { confirm } = Modal;

const MessageList = ({ data, loadMoreData, setRestart, ...props }) => {
  let { socket } = useSocket();
  const formatDate = (date) => {
    const formatted = moment(date).format('DD/MM/YYYY');
    return formatted;
  };

  const isAdmin = some(props.roles, ['name', 'ADMIN']);
  const isGteConcesion = some(props.roles, ['name', 'GERENTE CONCESION']);

  const countUnreadedMessages = async (id_user) => {
    const data = {
      id_user: id_user,
    };
    try {
      const response = await messages.getCountUnreadedMessage(data);
      props.setCountUnreadedMessage(response.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const deleteMessage = (item) => {
    console.log(item, 'items');
    confirm({
      title: '¿Esta seguro de eliminar este mensaje?',
      icon: <ExclamationCircleOutlined />,
      content: `El mensaje con el asunto a eliminar: ${item.subject}`,
      onOk() {
        const data = {
          id_message: item.id,
        };
        Promise.all([messages.deleteMessage(data)])
          .then((response) => {
            if (response[0].data.type == 'error') {
              openNotification('error', `El mensaje no pudo ser eliminado.`, true);
            } else if (response[0].data.type == 'success') {
              setRestart(true);
              countUnreadedMessages(item.id_user);
              socket.emit('eliminar_correo', response.data)
              openNotification('success', 'Mensaje eliminado', true);

            }
          })
          .catch((error) => {
            openNotification('error', `El mensaje no pudo ser eliminado.`, true);
            throw error;
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <div className="">
      {data ? (
            <List
              itemLayout="horizontal"
              dataSource={data.data}
              bordered={true}
              pagination={true}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <span className={item.message_read ? '' : 'message-list-title-readed'}>
                      {formatDate(item.created_at)}
                    </span>,
                    <Link to={`/mensaje/${item.id}`}>Ver Mensaje</Link>,
                    isAdmin || isGteConcesion ? (
                      <Button
                        type="danger"
                        ghost
                        icon={<DeleteOutlined />}
                        onClick={() => deleteMessage(item)}
                      >
                        Eliminar
                      </Button>
                    ) : (
                      <></>
                    ),
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <span className={item.message_read ? '' : 'message-list-title-readed'}>
                        {item.subject}
                      </span>
                    }
                  />
                </List.Item>
              )}
            />
      ) : (
        <List bordered={true} itemLayout="horizontal" />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCountUnreadedMessage: (unreaded) =>
    dispatch({ type: loginTypes.COUNT_UNREADED_MESSAGE, unreaded }),
});

const mapStateToProps = (state) => {
  const { user } = state.login;
  return {
    user_id: user.user_id,
    roles: user.roles,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
