import { axiosApi, API_BACK } from '../conf/configurationProd';
import authHeader from './utils/authHeader';
import handleResponse from './utils/handleResponse';


const getDataLocationRep = (values) => {
    const requestOptions = {
      headers: authHeader(),
    };
    return axiosApi
      .post(`${API_BACK}get_list_location_rep?page=${values.page}`, values, requestOptions)
      .then((response) => response)
      .catch((error) => handleResponse(error));
}

const getListSalesLocations = () => {
    const requestOptions = {
      headers: authHeader(),
    };
    return axiosApi
      .get(`${API_BACK}get_list_sales_locations`, requestOptions)
      .then((response) => response)
      .catch((error) => handleResponse(error));
}

const updateSalesLocations = (values) => {
    const requestOptions = {
      headers: authHeader(),
    };
    return axiosApi
      .post(`${API_BACK}update_sale_location`, values, requestOptions)
      .then((response) => response)
      .catch((error) => handleResponse(error));
}
const createBreak = (values) => {
    const requestOptions = {
      headers: authHeader(),
    };
    return axiosApi
      .post(`${API_BACK}create_break_rep`, values, requestOptions)
      .then((response) => response)
      .catch((error) => handleResponse(error));
}
const repsAttendanceReport = (values) => {
    const requestOptions = {
      headers: authHeader(),
    };
    return axiosApi
      .post(`${API_BACK}reps_attendance_report`, values, {
        ...requestOptions,
        responseType: 'blob',
      })
      .then((response) => response)
      .catch((error) => handleResponse(error));
}
const disabledLocation = () => {
    const requestOptions = {
      headers: authHeader(),
    };
    return axiosApi
      .get(`${API_BACK}disabled_location`, requestOptions)
      .then((response) => response)
      .catch((error) => handleResponse(error));
}

const addRepLocation = (values) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}add_rep_location`, values, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

const removeLocation = (data) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}remove_rep_location`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

const getRepsChannel = (values) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}users-main-roles-by-id/${values}`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

const validateLocation = (values) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}validate_rep_location`, values, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

const turnEndRep = (value) => {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}turn_end_rep`, value, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}


export const locationReps = {
    getDataLocationRep,
    getListSalesLocations,
    updateSalesLocations,
    createBreak,
    repsAttendanceReport,
    disabledLocation,
    addRepLocation,
    getRepsChannel,
    validateLocation,
    turnEndRep,
    removeLocation
};