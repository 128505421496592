import React, { useEffect, useState } from 'react';
import './styles/styles.css';
import { Col, Row, Button, Modal, Select } from 'antd';
import TableHotel from '../../components/Channels/TableHotel';
import FormAddHotel from '../../components/Channels/FormAddHotel';
import { userService } from '../../services/user-services';
import { connect } from 'react-redux';
//import FilterHotel from '../../components/Channels/FilterHotel'; Filtro que estaba anteriormente (por canal y hotel)
const { Option } = Select;
const channels = ({ ...props }) => {
  const [isOpenAddHotel, setIsOpenAddHotel] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [hotelsList, setHotelsList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [channel, setChannel] = useState(props.user.channels[0].id);
  // const [filter, setFilter] = useState({}); Filtro que estaba anteriormente (por canal y hotel)

  useEffect(() => {
    getHotels();
  }, [refresh]);
  useEffect(() => {
    getHotelsList();
  }, []);
  // Filtro que estaba anteriormente (por canal y hotel)
  // useEffect(() => {
  //   const isEmpty = Object.keys(filter).length === 0;
  //   if (!isEmpty) {
  //     setChannel(filter.channel_id);
  //     getFilteredHotels(filter);
  //   }
  // }, [filter]);

  useEffect(() => {
    getHotels();
  }, [channel]);

  const showModalAddHotel = () => {
    setIsOpenAddHotel(true);
  };

  const handleCancel = () => {
    setIsOpenAddHotel(false);
  };

  const getHotelsList = async () => {
    try {
      const response = await userService.getHotels(0);
      return setHotelsList(response.data);
    } catch (error) {
      return error;
    }
  };

  const getHotels = async () => {
    if (refresh == true) {
      setRefresh(false);
    }
    try {
      const response = await userService.getHotels(channel);
      return setHotels(response.data);
    } catch (error) {
      return error;
    }
  };

  // Filtro que estaba anteriormente (por canal y hotel)
  // const getFilteredHotels = async (data) => {
  //   try {
  //     const response = await userService.filterHotels(data);
  //     return setHotels(response.data);
  //   } catch (error) {
  //     return error;
  //   }
  // };

  const handleChangeChannel = (value) => {
    setChannel(value);
  };

  return (
    <div>
      <div>
        <span className="font-principal-title-channel">Canales</span>
      </div>
      <Row gutter={[16, 16]}>
        {/* <FilterHotel channelList={props.user.channels} hotelsList={hotels} setFilter={setFilter}/> Filtro que estaba anteriormente (por canal y hotel) */} 
        <Col xs={12} sm={6} md={4} lg={4}>
          <Select
            showSearch
            optionFilterProp="label"
            defaultValue={props.user.channels[0].id}
            style={{
              width: '100%',
            }}
            onChange={(id) => handleChangeChannel(id)}
          >
            {props.user.channels.map((channel) => (
              <Option label={channel.canal} key={channel.id} value={channel.id}>
                {channel.canal}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="pb-1 display-justify-end channel-padding-bottom">
        <Col>
          <Button type="primary" onClick={showModalAddHotel}>
            Agregar hotel
          </Button>
          <Modal
            title={
              <span className="channel-font-label channel-font-size-label">Agregar hotel</span>
            }
            visible={isOpenAddHotel}
            onCancel={handleCancel}
            footer={null}
          >
            <FormAddHotel
              hotels={hotelsList}
              channelId={channel}
              setIsOpenAddHotel={setIsOpenAddHotel}
              setRefresh={setRefresh}
            />
          </Modal>
        </Col>
      </Row>
      <div>
        <TableHotel data={hotels} setRefresh={setRefresh} setChannel={setChannel} />
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}
export default connect(mapStateToProps)(channels);
