/*
En este archivo exportamos las constantes de las operaciones para poder comunicar
Los Actions con los Reducers
*/
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';
const LOGOUT = 'LOGOUT';
const CHANGE_CONSECION = 'CHANGE_CONSECION';
const CHANGE_CHANNEL = 'CHANGE_CHANNEL';
const COUNT_UNREADED_MESSAGE = 'COUNT_UNREADED_MESSAGE';

export const loginTypes = {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  CHANGE_CONSECION,
  CHANGE_CHANNEL,
  COUNT_UNREADED_MESSAGE,
};
