import { axiosApi, API_BACK } from '../conf/configurationProd';
import authHeader from './utils/authHeader';
import handleResponse from './utils/handleResponse';

function getSupervisorList() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}assigment-role/get/supervisor-list`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function getAllLocationList() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}assigment-role/get/location-list`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function getAssigmentList() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}assigment-role/get/assigment-list`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function filterAssigmentList(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}assigment-role/get/filter-assigment-list`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function createAssigment(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}assigment-role/post/create-assigment`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function updateAssigment(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}assigment-role/post/update-assigment`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function deleteAssigment(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}assigment-role/post/delete-assigment`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

export const dailyAssigmentRole = {
  getSupervisorList,
  getAssigmentList,
  createAssigment,
  updateAssigment,
  deleteAssigment,
  filterAssigmentList,
  getAllLocationList
};
