import Axios from 'axios';
import { axiosApi, API_URL, API_BACK  } from '../conf/configurationProd';
import { history } from '../store/history';
import store from '../store/store';
import { userActions } from '../actions/user-actions';
import { timeActions } from '../actions/timer-actions';
import { getCookie, setCookie } from './utils/cookie';
import authHeader from './utils/authHeader';
// NOTA: USAR INSTANCIA DE AXIOS PARA PETICIONES A LA API con excepción de LOGIN y REGISTRO
function login(username, password) {
  return Axios.post(
    `${API_URL}/login/crm`,
    { email: username, password },
    { headers: { 'Content-Type': 'application/json', Accept: 'application/json' } }
  )
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    });
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    // debe concatenar el header
    headers: authHeader(),
  };
  return fetch(`${apiUrl}users?page=2`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user, token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`${apiUrl}/register`, requestOptions).then(handleResponse);
}

function update(user, token) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`${apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);
}

function getHotels(id) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.get(`${API_BACK}usuarios/hoteles/${id}`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function addHotelToChannel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_BACK}usuario/sales-places/agregar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function deleteHotelToChannel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_BACK}usuarios/sales/eliminar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function addLocationsHotel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_BACK}usuarios/sales-location/agregar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function editLocationsHotel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_BACK}usuarios/sales-location/editar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function deleteLocationsHotel(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_BACK}usuarios/sales-location/eliminar`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function changeStatusLocation(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_BACK}usuarios/sales-place/status`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function filterHotels(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_BACK}usuarios/filter-hoteles`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function filterLocations(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return Axios.post(`${API_BACK}usuarios/filter-locations`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function handleResponse(error) {
  if (error.response) {
    if (error.response.status === 401) {
      store.dispatch(userActions.logout());
      history.push('/');
    }
    return Promise.reject(error.response.data);
  }
  if (error.request) {
    return Promise.reject(error.request);
  }
  return Promise.reject(error.message);
}
export const userService = {
  login,
  register,
  getAll,
  getById,
  update,
  getHotels,
  addHotelToChannel,
  deleteHotelToChannel,
  addLocationsHotel,
  editLocationsHotel,
  deleteLocationsHotel,
  changeStatusLocation,
  filterHotels,
  filterLocations
};
axiosApi.interceptors.request.use((request) => {
  // intercepta cualquier Petición AXIOS, No usar en Peticiones usadas en componendidUpdate...
  const isCookie = getCookie('startedSessionAt');
  // Cada que haya una petición al Servidor resetea la Cokie
  if (isCookie) {
    setCookie('startedSessionAt', new Date(), 60);
  }
  store.dispatch(timeActions.resetTimer());
  return request;
});
