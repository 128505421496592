import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './styles/styles.css';
import { Field, Form, Formik } from 'formik';
import { AntInput } from '../AntFields/AntFields';
import openNotification from '../Notificaciones';
import * as Yup from 'yup';
import { userService } from '../../services/user-services';
import { reserva } from '../../services/reservacion';
import FilterLocation from './FilterLocation';
const { confirm } = Modal;

const FormAddLocation = ({ hotelId }) => {
  const [dataLocations, setDataLocations] = useState([]);
  const [dataLocationsAux, setDataLocationsAux] = useState([]);
  const [filter, setFilter] = useState([]);

  const schema = Yup.object().shape({
    locacion: Yup.string().required('Requerido'),
  });
  useEffect(() => {
    getLocations(hotelId);
  }, []);

  useEffect(() => {
    getLocations(hotelId);
  }, [hotelId]);

  useEffect(() => {
    getFilteredLocations(filter);
  }, [filter]);

  const columns = [
    {
      title: <span className="channel-font-table-title">Locación</span>,
      dataIndex: 'locacion',
      width: '100%',
      key: 'locacion',
      render: (_, record) => (
        <>
          <Input
            type="text"
            value={record.locacion}
            onChange={(e) => handleLocationChange(e, record)}
            onBlur={() => editInput(record)}
          />
        </>
      ),
    },
    {
      title: <span className="channel-font-table-title">Eliminar</span>,
      key: 'action',
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            danger
            onClick={() => deleteLocation(record)}
          />
        </>
      ),
    },
  ];

  const getLocations = async (id) => {
    setDataLocations([]);
    try {
      const response = await reserva.hotelesLocacion(id);
      setDataLocationsAux(response.data);
      return setDataLocations(response.data);
    } catch (error) {
      return error;
    }
  };
  const editInput = async (item) => {
    let data = {
      id: item.id,
      percent: 0,
    };

    dataLocationsAux.map((location) => {
      if (location.id === item.id) {
        if (location.locacion !== item.locacion) {
          data = {
            ...data,
            locacion: item.locacion,
          };
          userService
            .editLocationsHotel(data)
            .then((response) => {
              setDataLocationsAux(dataLocations);
              openNotification('success', 'Locación editada', true);
            })
            .catch((error) => {
              openNotification('error', 'Error al editar su locación', true);
            });
        }
      }
    });
  };
  const handleLocationChange = (e, key) => {
    const newData = dataLocations.map((item) => {
      if (item.id === key.id) {
        return { ...item, locacion: e.target.value };
      }
      return item;
    });
    setDataLocations(newData);
  };
  const handleSubmit = async (item) => {
    const data = {
      id_hotel: hotelId,
      locacion: item.locacion,
      parcent: 0,
    };
    try {
      const response = await userService.addLocationsHotel(data);
      openNotification('success', 'Locación agregada', true);
      getLocations(hotelId);
      return response;
    } catch (error) {
      openNotification('error', `Error al agregar la locación. Error: ${error.message}`, true);
    }
  };
  const deleteLocation = (item) => {
    confirm({
      title: '¿Esta seguro de eliminar esta locación?',
      icon: <ExclamationCircleOutlined />,
      content: ``,
      onOk() {
        Promise.all([userService.deleteLocationsHotel(item.id)])
          .then((response) => {
            const newData = dataLocations.filter((location) => location.id != item.id);
            setDataLocations(newData);
            setDataLocationsAux(newData);
            openNotification('success', 'Locación eliminada', true);
            return response.data;
          })
          .catch((error) => {
            openNotification('error', `Error al eliminar la locación`, true);
            return error;
          });
      },
      onCancel() {},
    });
  };

  const getFilteredLocations = async (item) => {
    try {
      const response = await userService.filterLocations(item);
      setDataLocations(response.data);
    } catch (error) {
      return error;
    }
  };

  return (
    <div>
      <FilterLocation locations={dataLocationsAux} setFilter={setFilter} hotelId={hotelId}  />
      <Formik
        initialValues={{
          location: '',
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            <Row gutter={[16, 16]}>
              <Col xs={12} sm={8} md={8} lg={8}>
                <Field
                  allowClear
                  style={{ width: '100%' }}
                  name="locacion"
                  label="Locación"
                  autoComplete="off"
                  placeholder="Ingresa locación"
                  component={AntInput}
                  type="text"
                />
              </Col>
              <Col className="channel-display-align-center" xs={12} sm={4} md={4} lg={4}>
                <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                  Agregar locación
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      <div>
        <Table
          className="channel-table-bg channel-pagination-bg"
          columns={columns}
          dataSource={dataLocations}
          pagination={{ pageSize: 5 }}
        />
      </div>
    </div>
  );
};
export default FormAddLocation;
