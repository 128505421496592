import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';
import login from './login-reducer';
import alert from './alert-reducer';
import time from './time-reducer';
import cart from './cart-reducers';
import filter from './dashboard-filter-reducer';
import nav from './navbar-reducer';
import modalFilter from './dashboard-modal-filter-reducer';

const config = {
  key: 'login',
  storage,
  whitelist: ['login', 'position', 'cart'],
};
const rootReducer = persistCombineReducers(config, {
  login,
  alert,
  time,
  cart,
  filter,
  nav,
  modalFilter
});

export default rootReducer;
