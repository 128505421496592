import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { AntSelect } from '../AntFields/AntFields';
import { Button, Col, Row, Select, Tag } from 'antd';
import { connect } from 'react-redux';
const { Option } = Select;
const FilterLocation = ({ locations, setFilter, hotelId }) => {

    const formikRef = useRef();
    useEffect(() => {
        if (formikRef.current) {
            formikRef.current.resetForm();
          }
    }, [hotelId])

  return (
    <Formik
    innerRef={formikRef}
      initialValues={{
        locations_id: []
      }}
      onSubmit={(values) => {
        setFilter(values);
      }}
    >
      {({ isSubmitting, resetForm }) => (
        <Form>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={9} md={9} lg={9} >
              <Field
                allowClear
                style={{ width: '100%' }}
                name="locations_id"
                label="Buscar locaciones"
                autoComplete="off"
                component={AntSelect}
                placeholder="Buscar una o más locaciones"
                mode="multiple"
                selectOptions={() =>
                    locations.map((item) => (
                      <Option label={item.locacion} key={item.id} value={item.id}>
                        {item.locacion}
                      </Option>
                    ))
                }
              />
            </Col>
            <Col className="channel-display-align-center" xs={12} sm={3} md={3} lg={3}>
              <Button htmlType="submit" block>
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}
export default connect(mapStateToProps)(FilterLocation);
