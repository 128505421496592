import { loginTypes } from '../constants';

const INITIAL_STATE = {
  loggingIn: false,
  loggedIn: false,
  showModalConsesion: true,
  user: { roles: [] },
};
const login = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user,
      };
    case loginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
      };
    case loginTypes.CHANGE_CHANNEL:
      return {
        ...state,
        user: {
          ...state.user,
          channel: action.channel,
        },
      };
    case loginTypes.COUNT_UNREADED_MESSAGE:
      return {
        ...state,
        user:{
          ...state.user,
          unreadedMessage: action.unreaded,
        }
      }
    case loginTypes.LOGIN_ERROR:
      return INITIAL_STATE;
    case loginTypes.CHANGE_CONSECION:
      return {
        ...state,
        showModalConsesion: false,
        user: {
          ...state.user,
          concesion: {
            ...state.user.concesion,
            hotel: action.concesion.hotel,
            locationZone: action.concesion.locationZone,
          },
        },
      };
    case loginTypes.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default login;
