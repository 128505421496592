import moment from 'moment';
import filterTypes from '../constants/dashboard-filter-types';

const initialState = {
  dateInit: moment().format('Y-MM-DD'),
  dateFin: moment().format('Y-MM-DD'),
  iva: 'siniva',
  currency: 'usd',
  location: [],
  product: [1, 2, 3, 4],
  channel: '',
  user: [],
  providers: [],
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case filterTypes.FILTER_RANGE_DATE:
      return {
        ...state,
        dateInit: action.dateInit,
        dateFin: action.dateFin,
      };
    case filterTypes.FILTER_IVA:
      return {
        ...state,
        iva: action.iva,
      };
    case filterTypes.FILTER_CURRENCY:
      return {
        ...state,
        currency: action.currency,
      };
    case filterTypes.FILTER_LOCATION:
      return {
        ...state,
        location: action.location,
      };
    case filterTypes.FILTER_PROVIDER:
      return {
        ...state,
        providers: action.providers,
      };
    case filterTypes.FILTER_PRODUCTS:
      return {
        ...state,
        product: action.product,
      };
    case filterTypes.FILTER_CHANNELS:
      return {
        ...state,
        channel: action.channel,
      };
    case filterTypes.FILTER_USERS:
      return {
        ...state,
        user: action.user,
      };
    case filterTypes.FILTER_CLEAR:
      return {
        dateInit: moment().format('Y-MM-DD'),
        dateFin: moment().format('Y-MM-DD'),
        iva: 'siniva',
        currency: 'usd',
        location: [],
        product: [1, 2, 3, 4],
        channel: '',
        user: [],
        providers: [],
      };
    default:
      return state;
  }
};

export default filter;
