import React, { useEffect, useState } from 'react';
import { Menu, Tag, Table } from 'antd';
import { Link } from 'react-router-dom';
import './styles.styl';
import { intersectionWith } from 'lodash';
import { MenuRoutes, rootSubmenuKeys } from './paths';
import { connect } from 'react-redux';
import { some } from 'lodash';
import { Modal as AntModal } from 'antd';
import { reserva } from '../../services/reservacion';

const { SubMenu } = Menu;
const LeftMenu = ({ type, location, user, ...props }) => {
  // console.log(user);
  const isAfiliado = some(props.roles, ['name', 'AFILIADO']);
  const map = (item) => {
    // AL parecer no pinta por Vista, pero de ser asi, asignar attr. Roles en cada Path
    if (item.children) {
      if (item.roles) {
        if (
          intersectionWith(item.roles, user.roles, (roles, rol) => roles == rol.name).length === 0
        ) {
          return null;
        }
        return (
          user.permissions?.includes(item.key) &&
            <SubMenu
              key={item.key}
              title={<span className="submenu-title-wrapper">{item.name}</span>}
            >
              {item.children.map(map)}
            </SubMenu>
        );
      }
      return (
          user.permissions?.includes(item.key) &&
            <SubMenu key={item.key} title={<span className="submenu-title-wrapper">{item.name}</span>}>
            {item.children.map(map)}
            </SubMenu>
      );
    }
    return (
      user.permissions?.includes(item.key) &&
        <Menu.Item key={item.path}>
          <Link to={item.path}>{item.name}</Link>
        </Menu.Item>
    );
  };
  const menu = MenuRoutes.map(map);
  const [openKeys, setOpenKeys] = useState([]);
  const [exchangeRate, setexchangeRate] = useState(0);
  const [modalCurrency, setmodalCurrency] = useState(false);
  const [dataCurrency, setDataCurrency] = useState();

  const onOpenChange = (currentOpenKeys) => {
    const latestOpenKey = currentOpenKeys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(currentOpenKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const {
    concesion: { locationZone, hotel },
  } = user;

  useEffect(() => {
    Promise.all([
      reserva.tipoCambioDia()
    ]).then(function([
      tipoCambioDia
    ]){
      setexchangeRate(tipoCambioDia.data.value)
    })
  },[])

  const btnCurrencyClick = async () => {
    setmodalCurrency(true);
    const response = await reserva.getAllCurrency();
    if (response.data) {
      setDataCurrency(response.data)
      console.log(response.data)
    }
  }
  const columns = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Cambios',
      dataIndex: 'cambios',
      key: 'cambios',
      render: (cambios) => (
        <>
          {cambios.map((cambio) => (
            <Tag key={cambio.id} color={getColorByValue(cambio.code)}>
              {cambio.code}: {cambio.value}
            </Tag>
          ))}
        </>
      ),
    },
  ];

  const getColorByValue = (code) => {
    if (code == 'USD') {
      return 'blue'
    }else if (code == 'MXN') {
      return 'green'
    }else if (code == 'EUR') {
      return 'orange'
    }else if (code == 'CAD') {
      return 'red'
    }else if (code == 'GBP') {
      return 'cyan'
    }
  };

  return (
    <Menu
      mode={type || 'horizontal'}
      theme={type ? 'dark' : ''}
      selectedKeys={[location.pathname]}
      openKeys={openKeys}
      className="custom_menu"
      defaultOpenKeys={openKeys}
      onOpenChange={(e) => {
        onOpenChange(e);
      }}
    >
      {!isAfiliado && (
        <Menu.Item key="path">
          <span>
            {hotel ? `${hotel.name}/` : ''}
            {hotel?.canal_name}/{locationZone}
          </span>
        </Menu.Item>
      )}
      {/* NOta Habilitar cuando haya un menu */}
      {menu}
      <Menu.Item key="tp">
        <div>
            <span
              onClick={btnCurrencyClick} 
            >
              TC: ${exchangeRate}
            </span>
          <AntModal
            title="Tipos de cambio del día"
            visible={modalCurrency}
            onOk={() => setmodalCurrency(false)}
            onCancel={() => setmodalCurrency(false)}
            width="auto" // Establece el ancho del modal como "auto" para que se ajuste al contenido
            style={{ maxWidth: '1000px', minWidth: '300px' }}
            destroyOnClose={true}
            cancelButtonProps={{ disabled: true }}
            //okButtonProps={{ disabled: true }}
          >
            <Table columns={columns} dataSource={dataCurrency} pagination={false} />
          </AntModal>
        </div>
      </Menu.Item>
    </Menu>
  );
};

function mapStateToProps(state) {
  const { user, showModalConsesion } = state.login;
  return {
    user_id: user.user_id,
    roles: user.roles,
    permissions: user.permissions
  };
}

const ConnectedCheckOut = connect(mapStateToProps)(LeftMenu);
export default ConnectedCheckOut;
