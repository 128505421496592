const authHeader = () => {
  const store = JSON.parse(localStorage.getItem('persist:login'));
  const login = JSON.parse(store.login);
  const { user } = login;
  if (store && user.token) {
    return {
      Authorization: `Bearer ${user.token}`,
      'Content-Type': 'application/json',
    };
  }
  return null;
};

export default authHeader;
