import React, { Component } from 'react';
import { connect } from 'react-redux';
import { timeActions } from '../../actions/timer-actions';
import { userActions } from '../../actions/user-actions';
import { alertActions } from '../../actions/alert-actions';
import { getCookie, setCookie } from '../../services/utils/cookie';
class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = { time: {}, seconds: 0, loading: true };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);

    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);

    const obj = {
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  async componentDidMount() {
    await this.props.start();
    let timeLeftVar = 0;
    await this.setState(
      {
        seconds: this.props.baseTime,
      },
      () => {
        timeLeftVar = this.secondsToTime(this.state.seconds);
      }
    );
    this.setState({ time: timeLeftVar, loading: false }, () => {
      this.startTimer();
    });
  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    const initClock = getCookie('startedSessionAt');
    const seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds,
    });
    // if (
    //   seconds == 60 * 1
    //   || seconds == 60 * 5
    //   || seconds == 60 * 10
    //   || seconds == 60 * 15
    //   || seconds == 60 * 20
    //   || seconds == 60 * 25
    // ) {
    //   // verifica que no se haya movido de su posicion cada 5 minutos si no se resetea el contador por inactividad....
    //   // const distance = getDistance(this.props.position, {
    //   //   lat: this.props.coords.latitude,
    //   //   lng: this.props.coords.longitude,
    //   // });
    //   // if(distance>distancia_establecida)
    //   // console.log(distance);  Exactitud + - 5 Metros...
    // }
    // valida si existe la cockie, si no Desloguea tambien...
    if (seconds == 0 || !initClock) {
      clearInterval(this.timer);
      this.props.logout();
      this.props.alertMessage({
        titulo: 'Sesión Expirada',
        description: `Inicie Sesión de Nuevo, Por favor.`,
      });
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const timeLeftVar = this.secondsToTime(nextProps.baseTime);
    if (nextProps.baseTime >= this.props.baseTime) {
      clearInterval(this.timer);
      this.timer = 0;
      this.setState(
        {
          seconds: nextProps.baseTime,
          time: timeLeftVar,
        },
        () => {
          this.startTimer();
        }
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  UNSAFE_componentWillMount() {
    const initClock = getCookie('startedSessionAt');
    if (!initClock && this.timer == 0) {
      setCookie('startedSessionAt', new Date(), 60);
    }
  }

  render() {
    // const { latitude, longitude } = this.props.coords;
    // console.log(this.props);
    // const distance = getDistance(start, stop)/1000
    // return !this.props.isGeolocationAvailable ? (
    //   <div>Your browser does not support Geolocation</div>
    // ) : !this.props.isGeolocationEnabled ? (
    //   <div>Geolocation is not enabled</div>
    // ) : this.props.coords ? (
    //   <table>
    //     <tbody>
    //       <tr>
    //         <td>latitude</td>
    //         <td>{this.props.coords.latitude}</td>
    //       </tr>
    //       <tr>
    //         <td>longitude</td>
    //         <td>{this.props.coords.longitude}</td>
    //       </tr>
    //       <tr>
    //         {/* preguntar si le sirve o no, validar la distancia de movimiento.... */}
    //         <td>
    //           Distance:
    //           {getDistance(
    //             { lat: 21.1377694, lng: -86.7684014 },
    //             { lat: 21.1376378, lng: -86.768716 },
    //           )}
    //         </td>
    //       </tr>
    //     </tbody>
    //   </table>
    // ) : (
    //   <div>Getting the location data&hellip; </div>
    // );
    return (
      <div>
        {/* {this.props.coords.latitude} */}
        {!this.state.loading && (
          <span>
            <i style={{ marginRight: 5 }} className="icon icon-clock" />
            {this.state.time.m}
            {/* {moment(this.props.startedAt)} */}:
            {this.state.time.s < 10 ? `0${this.state.time.s}` : this.state.time.s}
          </span>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  // const { position } = state.position;
  const { baseTime, startedAt, stoppedAt } = state.time;
  return {
    baseTime,
    startedAt,
    stoppedAt,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    start: (time) => {
      dispatch(timeActions.startTimer(time));
    },
    alertMessage(message) {
      dispatch(alertActions.info(message));
    },
    logout: () => {
      dispatch(userActions.logout());
    },
    // setPosition: (position) => {
    //   dispatch(positionActions.setPosition(position));
    // },
  };
}

const connectedCountDown = connect(mapStateToProps, mapDispatchToProps)(CountDown);
// const connectedCountDown = geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//     // watchPosition: true,
//   },
// })(
//   connect(
//     mapStateToProps,
//     mapDispatchToProps,
//   )(CountDown),
// );

export { connectedCountDown as CountDown };

// const getDistance = function (p1, p2) {
//   const R = 6378137; // Earth’s mean radius in meter
//   const dLat = deg2rad(p2.lat - p1.lat);
//   const dLong = deg2rad(p2.lng - p1.lng);
//   const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
//     + Math.cos(deg2rad(p1.lat))
//       * Math.cos(deg2rad(p2.lat))
//       * Math.sin(dLong / 2)
//       * Math.sin(dLong / 2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   const d = R * c;
//   return d; // returns the distance in meter
// };
// function deg2rad(deg) {
//   return deg * (Math.PI / 180);
// }
