import moment from 'moment';
import modalFilterTypes from '../constants/dashboard-modal-filter-types';

const initialState = {
  from: moment().format('Y-MM-DD'),
  to: moment().format('Y-MM-DD'),
  iva: 'siniva',
  currency: 'usd',
  location: [],
  providers: [],
  product: [1, 2, 3, 4],
  channel: '',
};

const modalFilter = (state = initialState, action) => {
  switch (action.type) {
    case modalFilterTypes.MODAL_FILTER_DATE_FROM:
      return {
        ...state,
        from: action.from,
      };
    case modalFilterTypes.MODAL_FILTER_DATE_TO:
      return {
        ...state,
        to: action.to,
      };
    case modalFilterTypes.MODAL_FILTER_IVA:
      return {
        ...state,
        iva: action.iva,
      };
    case modalFilterTypes.MODAL_FILTER_CURRENCY:
      return {
        ...state,
        currency: action.currency,
      };
    case modalFilterTypes.MODAL_FILTER_CHANNELS:
      return {
        ...state,
        channel: action.channel,
      };
    case modalFilterTypes.MODAL_FILTER_PRODUCTS:
      return {
        ...state,
        product: action.product,
      };
    case modalFilterTypes.MODAL_FILTER_LOCATIONS:
      return {
        ...state,
        location: action.location,
      };

    case modalFilterTypes.MODAL_FILTER_PROVIDERS:
      return {
        ...state,
        providers: action.providers,
      };

    case modalFilterTypes.MODAL_FILTER_CLEAR:
      return {
        from: moment().format('Y-MM-DD'),
        to: moment().format('Y-MM-DD'),
        iva: 'siniva',
        currency: 'usd',
        location: [],
        product: [1, 2, 3, 4],
        channel: '',
        providers: [],
      };
    default:
      return state;
  }
};
export default modalFilter;
