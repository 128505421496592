/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Modal, Button, Tooltip } from 'antd';
import './styles.styl';
import { omit } from 'lodash';

const CustomModal = ({ initOnReady, buttonProps, children, ...props }) => {
  const [visible, setVisible] = useState(initOnReady);
  const onCanceled = () => {
    setVisible(false);
  };
  const childrenWithProps = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      index,
      closemodal: () => onCanceled(),
      openmodal: () => setVisible(true),
    })
  );
  const { type, text } = buttonProps || {};
  return (
    <>
      {!initOnReady && buttonProps && (
        <>
          {buttonProps.toolTip ? (
            <Tooltip {...buttonProps.toolTip}>
              <Button
                {...omit(buttonProps, ['toolTip', 'text'])}
                type={type || 'default'}
                onClick={() => {
                  setVisible(true);
                }}
              >
                {text}
              </Button>
            </Tooltip>
          ) : (
            <Button
              {...buttonProps}
              type={type || 'default'}
              onClick={() => {
                setVisible(true);
              }}
            >
              {text}
            </Button>
          )}
        </>
      )}
      {props.customButton && (
        <button
          style={{ padding: '0px 1rem', cursor: 'pointer', width: '100%', textAlign: 'left' }}
          onClick={() => {
            setVisible(true);
          }}
        >
          {props.customButton}
        </button>
      )}
      <Modal
        {...props}
        centered
        className="custom-modal"
        visible={visible}
        onCancel={onCanceled}
        maskStyle={{ backgroundColor: 'rgba(255,255,255,0.75)' }}
        footer={null}
      >
        {childrenWithProps}
      </Modal>
    </>
  );
};

export default CustomModal;
