import './style/style.css';
import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import LoginWhatsApp from './login/login';
import CreateMessage from './createMessage/createMessage';
import { io } from 'socket.io-client';
import { messages } from '../../services/messages';

const WhatsAppWebHome = ({ setShowFormWhatsApp, showFormWhatsApp, onClose, isAdmin, isGteConcesion}) => {
  const [active, setActive] = useState(false);
  const [qrCodeURL, setQRCodeURL] = useState(null);
  const [showQR, setShowQR] = useState(false);
  const [chatGroups, setChatGroups] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [clientReady, setClientReady] = useState(false);
  const [spinTipText, setSpinTipText] = useState('Cargando...');
  const [spinTipTextWhatModal, setSpinTipTextWhatModal] = useState('Cargando...');

  let socket = io('https://socket-server-message.onrender.com');
  useEffect(() => {
    setLoading(true);
    setQRCodeURL(null);
    setSpinTipTextWhatModal('Cargando...');
    setSpinTipText('Cargando...');

    socket.on('qrCode', (newCode) => {
      console.log(newCode, 'qrcode');
      setShowQR(true);
      setQRCodeURL(newCode);
      setLoading(false);
    });

    socket.on('auth', (auth) => {
      console.log(auth, 'auth');
      setShowQR(false);
    });

    socket.on('ready', () => {
      setLoading(false);
      setClientReady(true);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  socket.on('closeSession', (session) => {
    if(session === true){
      setClientReady(false);
      setLoading(true);
      setSpinTipTextWhatModal('Cierre de sesión en curso...');
    }
  });


  useEffect(() => {
    socket.on('qrCode', (newCode) => {
      setShowQR(true);
      setQRCodeURL(newCode);
      setLoading(false);
    });
  }, [qrCodeURL]);

  useEffect(() => {
    console.log('gruposss');
    if (clientReady === true) {
      console.log(clientReady, 'clientReady despues del if');
      setSpinTipText('Buscando grupos...');
      setLoadingForm(true);

      getGroupChat();
    }
  }, [clientReady]);

  const getGroupChat = async() => {
    try {
      const response = await messages.getGroupChats();
      setLoadingForm(false);
      return setChatGroups(response);
    } catch (error) {
      console.log(error, 'error');
    }
  }

  const updateChatGroups = () => {
    setSpinTipText('Buscando grupos...');
    setLoadingForm(true);
    getGroupChat();
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} className="display-justify-center-form-whats-btn">
        <Spin tip={spinTipTextWhatModal} spinning={loading}></Spin>
        {clientReady ? (
          <Spin tip={spinTipText} spinning={loadingForm}>
            <div>
              <CreateMessage
                chatGroups={chatGroups}
                socket={socket}
                updateChatGroups={updateChatGroups}
                onClose={onClose}
                setLoading={setLoading}
                setSpinTipText={setSpinTipText}
                setClientReady={setClientReady}
                setSpinTipTextWhatModal={setSpinTipTextWhatModal}
                setLoadingForm={setLoadingForm}
                isAdmin={isAdmin}
                isGteConcesion={isGteConcesion}
              />
            </div>
          </Spin>
        ) : (
          <div>{showQR && <LoginWhatsApp qrCodeURL={qrCodeURL} active={active} />}</div>
        )}
      </Col>
    </Row>
  );
};
export default WhatsAppWebHome;
