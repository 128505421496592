import { DeleteOutlined, ExclamationCircleOutlined, FormOutlined } from '@ant-design/icons';
import { Space, Table, Tag, Button, Modal } from 'antd';
import React, { useState } from 'react';
import { dailyAssigmentRole } from '../../services/dailyAssigmentRole';
import openNotification from '../Notificaciones';
import AssigmentFormUpdate from './AssigmentFormUpdate';
const { confirm } = Modal;
const AssigmentList = ({data, supervisorList, setRestart }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState();
    const columns = [
        {
          title: <span className="span-title-table">Fecha</span>,
          dataIndex: ['assigment','date'],
          key: 'assigment.init_date',
        },
        {
          title: <span className="span-title-table">Horario inicio</span>,
          dataIndex: ['assigment','init_schedule'],
          key: 'assigment.end_date',
        },
        {
          title: <span className="span-title-table">Horario fin</span>,
          dataIndex: ['assigment','end_schedule'],
          key: 'assigment.end_date',
        },
        {
          title: <span className="span-title-table">Nombre de supervisor</span>,
          dataIndex: 'userAssigment',
          key: 'userAssigment',
          render: (_, record) => (
            record.userAssigment.map((element)=> {
              return(
                <span>{element.name} {element.last_name}</span> 
              )
            })
          )
        },
        {
          title: <span className="span-title-table">Acciones</span>,
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <Button type="primary" icon={<FormOutlined />} onClick={() => editAssigment(record)} ghost
              >Editar</Button>
              <Button type="danger" icon={<DeleteOutlined />} onClick={() => deleteAssigment(record)} ghost>Eliminar</Button>
            </Space>
          ),
        },
      ];
      const editAssigment = (item) => {
        setIsModalOpen(true);
        setUpdateData(item);
      }
      const deleteAssigment = (item) => {
        confirm({
          title: '¿Esta seguro de eliminar esta asignación?',
          icon: <ExclamationCircleOutlined />,
          content: ``,
          onOk() {
            const data = {
              id_assigment: item.assigment.id,
              active: false
            };
            Promise.all([dailyAssigmentRole.deleteAssigment(data)])
              .then((response) => {
                  openNotification('success', 'Asignación eliminada', true);
                  setRestart(true);
                  return response.data;
              })
              .catch((error) => {
                setRestart(true);
                openNotification('error', `No se pudo eliminar la asignación`, true);
                throw error;
              });
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
      const showModal = () => {
        setIsModalOpen(true);
    }
    const dontShowModal = () => {
        setIsModalOpen(false);
    }
    return (
      <div>
        <div className="padding-assigment-table">
          <Table columns={columns} dataSource={data} />
        </div>
        <Modal title="Actualizar asignación" visible={isModalOpen} onCancel={dontShowModal} footer={null}>
            <AssigmentFormUpdate supervisorList={supervisorList} setIsModalOpen={setIsModalOpen} data={updateData} setRestart={setRestart} />
        </Modal>
      </div>

    );
}
export default AssigmentList;