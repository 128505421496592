const filterTypes = {
  FILTER_RANGE_DATE: 'FILTER_RANGE_DATE',
  FILTER_IVA: 'FILTER_IVA',
  FILTER_CURRENCY: 'FILTER_CURRENCY',
  FILTER_LOCATION: 'FILTER_LOCATION',
  FILTER_PROVIDER: 'FILTER_PROVIDER',
  FILTER_PRODUCTS: 'FILTER_PRODUCTS',
  FILTER_CHANNELS: 'FILTER_CHANNELS',
  FILTER_CLEAR: 'FILTER_CLEAR',
  FILTER_USERS: 'FILTER_USERS'
};

export default filterTypes;
