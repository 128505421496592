import navbarTypes from "../constants/navbar-types";

const initialState = {
    active: 1,
}

const nav = (state = initialState, action) => {
    switch (action.type) {
        case navbarTypes.NAVBAR_MOBILE:
            return {
                //...state,
                active: action.active
            };
        default:
            return state;
    }
};

export default nav;