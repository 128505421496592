import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import RightMenu from './rightMenu';
import './styles.styl';
import LeftMenu from './leftMenu';
import logo from '../../static/img/logos/cb-white.svg';
import logo2 from '../../static/img/logos/logo.png';
import logoMobile from '../../static/img/logos/isotipo-movil.png';
import { userActions } from '../../actions/user-actions';

const NavBar = (props) => {
  const [visible, setVisible] = useState(false);
  const roles = props.roles.map((r) => r.name);
  const { user } = props;
  useEffect(() => {
    setVisible(false);
  }, [props.location.pathname]);
  return (
    <nav className="navbar">
      <div className="navbar__wrap">
        <div className="navbar__logo navbar__logo-background">
          <Link to="/">
            <div className="navbar__logo-background navbar__logo-desk">
              <img src={logo2} alt="Caribe Maya Logo" />
            </div>
            <div className="navbar__logo-mobile">
              <img src={logoMobile} alt="Caribe Maya Logo" />
            </div>
          </Link>
        </div>
        <div className="navbar__container">
          <div className="navbar__menu">
            <LeftMenu user={props.user} location={props.location} roles={roles} />
            <RightMenu
              user={props.user}
              logout={props.logout}
              roles={roles}
              location={props.location}
            />
          </div>
          <Drawer
            title={
              <div>
                Menu
                <button
                  onClick={() => {
                    setVisible(false);
                  }}
                  className="pull-right"
                >
                  <span className="icon icon-cross-circle" />
                </button>
              </div>
            }
            placement="left"
            className="drawer"
            bodyStyle={{ padding: 0 }}
            closable={false}
            onClose={() => {
              setVisible(false);
            }}
            visible={visible}
          >
            <LeftMenu user={props.user} type="inline" roles={roles} location={props.location} />
            <RightMenu
              user={props.user}
              logout={props.logout}
              roles={roles}
              type="inline"
              location={props.location}
            />
          </Drawer>
        </div>
        <button
          type="button"
          className="navbar__btn"
          onClick={() => {
            setVisible(true);
          }}
        >
          <span className="icon icon--xxl icon-menu" />
        </button>
      </div>
    </nav>
  );
};
const mapStateToProps = (state) => {
  const { login } = state;
  const { user } = login;
  return {
    user,
    roles: user.roles,
  };
};
const mapDispatchToProps = (dispatch) => ({
  logout() {
    dispatch(userActions.logout());
  },
});
const ConnectedNavbar = connect(mapStateToProps, mapDispatchToProps)(NavBar);
export default ConnectedNavbar;
