const modalFilterTypes = {
  MODAL_FILTER_DATE_FROM: ' MODAL_FILTER_DATE_FROM',
  MODAL_FILTER_DATE_TO: 'MODAL_FILTER_DATE_TO',
  MODAL_FILTER_CURRENCY: 'MODAL_FILTER_CURRENCY',
  MODAL_FILTER_IVA: 'MODAL_FILTER_IVA',
  MODAL_FILTER_CHANNELS: 'MODAL_FILTER_CHANNELS',
  MODAL_FILTER_PRODUCTS: 'MODAL_FILTER_PRODUCTS',
  MODAL_FILTER_LOCATIONS: 'MODAL_FILTER_LOCATIONS',
  MODAL_FILTER_PROVIDERS: 'MODAL_FILTER_PROVIDERS',
  MODAL_FILTER_CLEAR: 'MODAL_FILTER_CLEAR',
};
export default modalFilterTypes;
