/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Alert } from 'antd';
import './styles.styl';

const Alerta = (props) => {
  const { animation, ...restProps } = props;
  const [visible, setVisible] = useState(true);
  const handleClose = () => {
    setVisible(false);
    props.clearMessage();
  };
  return (
    <div>
      {visible ? (
        <Alert
          {...restProps}
          className={`alert_custom ${animation ? ' alert_custom alert_custom--animate' : ''}`}
          closable
          afterClose={handleClose}
        />
      ) : null}
    </div>
  );
};

export default Alerta;
