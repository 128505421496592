import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import MainLayout from './InnerLayout';
import { intersectionWith } from 'lodash';
export const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let presents = intersectionWith(allowedRoles, rest.auth.roles, (roles, rol) => {
          return roles == rol.name;
        });
        let isEnter = presents.length > 0;
        if (!rest.auth.loggedIn) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
        if (allowedRoles && !isEnter) {
          return <div> No tienes los Permisos necesarios</div>;
        }
        return (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        );
      }}
    />
  );
};
