import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import rootReducer from '../reducers';

const loggerMiddleware = createLogger();
// console.log(process.env.NODE_ENV === 'DEVELOPMENT');
// const loggerMiddleware = createLogger({
//   predicate: (getState, action) => process.env.NODE_ENV === 'DEVELOPMENT',
// });
//const loggerMiddleware = '';
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddleware));
// store.dispatch(actionLoadElements())
export const persistor = persistStore(store);
export default store;
