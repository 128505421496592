import React, { useEffect, useState } from 'react';
import './styles/styles.css'
import AssigmentFilter from '../../components/DailyAssigmentRole/AssigmentFilter';
import AssigmentList from '../../components/DailyAssigmentRole/AssigmentList';
import { Button, Col, Modal, Row } from 'antd';
import { dailyAssigmentRole } from '../../services/dailyAssigmentRole';
import AssigmentForm from '../../components/DailyAssigmentRole/AssigmentForm';
import openNotification from '../../components/Notificaciones';

const DailyAssigmentRole = () => {

    const [list, setList] = useState([]);
    const [supervisorList, setSupervisorList] = useState();
    const [locationList, setLocationList] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [filter, setFilter] = useState();
    const [restart, setRestart] = useState();

    useEffect(() => {
        getDailyAssigmentRoleList();
        getSupervisorList();
        getLocationsList();
    }, []);
    useEffect(() => {
        if(filter != null){
            filterAssigment(filter);
        }
    }, [filter]);
    useEffect(() => {
        getDailyAssigmentRoleList();
    }, [restart]);

    const getDailyAssigmentRoleList = async() => {
        if(restart == true){
            setRestart(false);
        }
        try {
            const response = await dailyAssigmentRole.getAssigmentList();
            setList(response.data);
            return response;          
        } catch (error) {
            //openNotification("error", "Error al obtener las asignaciones", true);
            return error;  
        }
    }

    const getSupervisorList = async() => {
        try {
            const response = await dailyAssigmentRole.getSupervisorList();
            setSupervisorList(response.data);
            return response;     
        } catch (error) {
            //openNotification("error", "Error al obtener la lista de supervisores", true);
            return error;
        }
    }

    const getLocationsList = async() => {
        try {
            const response = await dailyAssigmentRole.getAllLocationList();
            setLocationList(response.data);
            return response;
        } catch (error) {
            return error;
        }
    }

    const filterAssigment = async(data) => {
        try {
            const response = await dailyAssigmentRole.filterAssigmentList(data);
            setList(response.data);
            return response;         
        } catch (error) {
            //openNotification("error", "Error al realizar el filtrado", true);
            return error;
        }
    }

    const showModal = () => {
        setIsModalOpen(true);
    }
    const dontShowModal = () => {
        setIsModalOpen(false);
    }

    return(
    <div>
        <div><span className="font-principal-title-assigment">Rol diario de supervisión de ventas</span></div>
        <AssigmentFilter supervisorList={supervisorList} setFilter={setFilter} />
        <Row className="padding-assigment-table">
            <Col className="font-title-assigment" xs={12} sm={6} md={6} lg={6}>
            Asignaciones
            </Col>
            <Col className="display-justify-end" xs={12} sm={6} md={6} lg={6}>
                <Button onClick={showModal}>Agregar nuevo rol diario</Button>
                <Modal title="Agregar nuevo rol diario" visible={isModalOpen} onCancel={dontShowModal} footer={null}>
                    <AssigmentForm supervisorList={supervisorList} locationList={locationList} setIsModalOpen={setIsModalOpen} setRestart={setRestart} />
                </Modal>
            </Col>
        </Row>
        <AssigmentList data={list} supervisorList={supervisorList} setRestart={setRestart} setIsOpen={setIsOpen}/>
    </div>
    );
}
export default DailyAssigmentRole;