import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import DisplayBookingForm from '../../components/LoginForm';
import { userActions } from '../../actions/user-actions';
import './styles.styl';
import logo from '../../static/img/logos/logo-vertical.png';

const initialValues = {
  user: 'admin@oasis.test',
  password: '',
};
const SignupSchema = Yup.object().shape({
  user: Yup.string().required('Requerido'),
  password: Yup.string().required('Requerido'),
});

const LoginPage = (props) => {
  const { loggingIn, loggedIn } = props;
  let history = useHistory();

  const handleSubmit = (formProps, actions) => {
    const { user, password } = formProps;
    const { dispatch } = props;

    dispatch(userActions.login(user, password)).catch((e) => {
      actions.setErrors(e);
      actions.setSubmitting(false);
    });
  };
  useEffect(() => {
    if (loggedIn) history.push('/dashboard');
  }, []);

  return (
    <div className="login">
      <div className="login__aside">&nbsp;</div>
      <div className="login__body">
        <Spin spinning={loggingIn} tip="Cargando...">
          <div className="login__brand">
            <img src={logo} alt="Caribe Maya" />
          </div>
          <div className="login__form">
            <p className="login__form__title">Acceso al Sistema</p>
            <Formik
              initialValues={initialValues}
              validationSchema={SignupSchema}
              onSubmit={handleSubmit}
            >
              {(props) => <DisplayBookingForm {...props} />}
            </Formik>
          </div>
        </Spin>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { loggingIn, loggedIn } = state.login;
  return {
    loggingIn,
    loggedIn,
  };
}
export default connect(mapStateToProps)(LoginPage);
