/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Card } from 'antd';
import './styles.styl';

const CustomCard = ({ title, children, cart, canceled, ...props }) => {
  return (
    <Card
      {...props}
      size="small"
      className={`${cart ? 'card_custom__cart' : 'card_custom'}`}
      title={title}
    >
      <div className="card_custom__cart--inner">{children}</div>
    </Card>
  );
};

export const DashboardCard = (props) => {
  const { className } = props;
  return (
    <Card
      {...props}
      size="large"
      className={`card-dashboard ${className || ''}`}
      title={
        <div className={`card-dashboard--header ${props.center ? ' text-center' : ''}`}>
          {props.title || 'Sin Titulo'}
        </div>
      }
    >
      <div className="card-dashboard--inner">{props.children}</div>
    </Card>
  );
};

export default CustomCard;
