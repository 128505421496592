import React from "react";
import { Button } from "antd";
import "./styles.styl";
import error from "../../static/img/notfound/404.png";
import logo from "../../static/img/notfound/logo-vertical.png";
import { withRouter } from "react-router-dom";
const NotFoundPage = (props) => {
  console.log(props);
  return (
    <div className="notfound">
      <div className="notfound__wrap">
        <div className="notfound__image mb-20 mb-40-md">
          <img src={error} alt="" />
        </div>
        <div className={`notfound__caption text-center`}>
          <h1>¡Oops! Página no encontrada</h1>
          <p>Lo sentimos, la página que solicitaste ya no está disponible o no existe.</p>
          <Button
            type="primary"
            className="notfound__btn mt-20"
            onClick={() => {
              props.history.goBack();
            }}
          >
            Regresar al Sitio
          </Button>
        </div>
        <div className="notfound__brand text-center mt-70 mt-100-md">
          <img src={logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(NotFoundPage);
