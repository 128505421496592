/* eslint-disable import/prefer-default-export */
export const cartTypes = {
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_ITEM: 'REMOVE_ITEM',
  REMOVE_DOCK: 'REMOVE_DOCK',
  ADD_TO_DOCK:'ADD_TO_DOCK',
  UPDATE_ITEM: 'UPDATE_ITEM',
  CLEAR_CART: 'CLEAR_CART',
  ADD_LEAD_INFO: 'ADD_LEAD_INFO',
  CHECKOUT_REQUEST: 'CHECKOUT_REQUEST',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS',
  CHECKOUT_FAILURE: 'CHECKOUT_FAILURE',
  CHECKOUT_UPDATE_TOTAL: 'CHECKOUT_TOTAL',
  CHECKOUT_UPDATE_ITEM_PRICE: 'CHECKOUT_ITEM_PRICE',
  HAS_PENDING_PAYMENT: 'HAS_PENDING_PAYMENT',
};
