import { pick, get, some } from 'lodash';
// import { Modal } from 'antd';
import { loginTypes } from '../constants';
import { userService } from '../services/user-services';
import { alertActions } from './alert-actions';
import { history } from '../store/history';
import { reserva } from '../services/reservacion';

function login(username, password) {
  function request(user) {
    return { type: loginTypes.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: loginTypes.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: loginTypes.LOGIN_ERROR, error };
  }
  return (dispatch) => {
    dispatch(request({ username }));
    return userService
      .login(username, password)
      .then((user) => {
        const { data } = user;
        // Aca esta mal :c solo toma el primero xD
        const concesion = data.concesion.map((c) => ({
          ...pick(c, ['hotel', 'products', 'zone', 'status']),
          locationZone: get(c, 'zone.name'),
        }))[0];
        const roles = data.roles.map((c) => ({
          ...pick(c, ['name']),
        }));
        dispatch(
          success({
            token: data.token,
            // Se podria separar, pero Nah.... :v
            // solo con propositos de prueba

            channels: [...data.channels],
            // Tomamos el primer canal, si desea cambiarlo podrá hacerlo despues (si tiene mas de uno)
            channel: data.channels[0],
            concesion,
            roles,
            setRepLocation: data.setRepLocation,
            permissions: data.permissions,
            ...data.user,
            rolesNuevos:data.rolesNuevos,
            roleLocations: data.roleLocations,
            products: data.products
          })
        );

        navigator.geolocation.getCurrentPosition(location => {
          let loginLogPayload = {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
            accuracy: location.coords.accuracy,
            channel: data.channels ?? [],
            setRepLocation: data.setRepLocation,
            hotel: concesion.hotel.id ?? 0,
            zone: concesion.zone.id ?? 0,
            user: data.user.user_id ?? 0,
            login_at: Math.floor(Date.now() / 1000)
          };

          reserva.logLogin(loginLogPayload)
            .then(res => console.log(res))
            .catch(res => console.error(res));
        });

        setTimeout(() => {
          history.push('/');
        }, 100);
        dispatch(
          alertActions.success({
            titulo: 'Login Successful',
            description: `Bienvenido ${data.user.userName} ${
              some(roles, ['name', 'TOURSALES'])
                ? `esta actualmente en la ZONA:${concesion.locationZone}`
                : ''
            }`,
          })
        );
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(failure(JSON.stringify(error)));
        return Promise.reject(error);
      });
  };
}

function logout() {
  // Limpiamos el carrito con cada Logout
  return (dispatch) => {
    dispatch({ type: 'CLEAR_CART' });
    dispatch({ type: loginTypes.LOGOUT });
  };
}
function changeConsecion(concesion) {
  return { type: loginTypes.CHANGE_CONSECION, concesion };
}
function changeChannel(channel) {
  return { type: loginTypes.CHANGE_CHANNEL, channel };
}
function setCountUnreadedMessage(unreaded){
  return {type: loginTypes.COUNT_UNREADED_MESSAGE, unreaded};
}

function register(user) {
  function request(data) {
    return { type: loginTypes.REGISTER_REQUEST, data };
  }
  function success(data) {
    return { type: loginTypes.REGISTER_SUCCESS, data };
  }
  function failure(error) {
    return { type: loginTypes.REGISTER_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request(user));
    userService.register(user).then(
      (data) => {
        dispatch(success(data));
        history.push('/login');
        dispatch(alertActions.success('Registration successful'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function getAll() {
  function request() {
    return { type: loginTypes.GETALL_REQUEST };
  }
  function success(users) {
    return { type: loginTypes.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: loginTypes.GETALL_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };
}

// prefixed function name with underscore because delete is a reserved word in javascript
function eliminate(id) {
  const request = (data) => ({ type: loginTypes.DELETE_REQUEST, data });
  const success = (data) => ({ type: loginTypes.DELETE_SUCCESS, data });
  const failure = (data, error) => ({ type: loginTypes.DELETE_FAILURE, data, error });
  return (dispatch) => {
    dispatch(request(id));
    userService.delete(id).then(
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };
}

export const userActions = {
  login,
  logout,
  register,
  getAll,
  changeConsecion,
  changeChannel,
  setCountUnreadedMessage,
  delete: eliminate,
};
