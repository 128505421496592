import { Button, Col, Row, Spin } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { AntDatePicker, AntSelect, AntTimePicker } from '../AntFields/AntFields';
import moment from 'moment';
import { dailyAssigmentRole } from '../../services/dailyAssigmentRole';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import openNotification from '../Notificaciones';

const AssigmentForm = ({ supervisorList, locationList, setIsModalOpen, setRestart, ...props }) => {
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    init_date: Yup.date().required('Requerido'),
    end_date: Yup.date().required('Requerido'),
    init_schedule: Yup.date().required('Requerido'),
    end_schedule: Yup.date().required('Requerido'),
    location: Yup.string().required('Requerido'),
    supervisor: Yup.string().required('Requerido'),
  });
  const createNewAssigment = async (item) => {
    const data = {
      init_date: item.init_date.format('YYYY-MM-DD'),
      end_date: item.end_date.format('YYYY-MM-DD'),
      init_schedule: item.init_schedule.format('HH:mm:ss'),
      end_schedule: item.end_schedule.format('HH:mm:ss'),
      id_channel: 1,
      id_assigment_user: item.supervisor,
      id_created_user: props.user.user_id,
      location: item.location,
    };
    try {
      const response = await dailyAssigmentRole.createAssigment(data);
      openNotification('success', 'Nueva asignación creada', true);
      setRestart(true);
      setIsModalOpen(false);
      setLoading(false);
      return response;
    } catch (error) {
      openNotification('error', 'Error al crear la nueva asignación', true);
      setRestart(true);
      setIsModalOpen(false);
      setLoading(false);
      return error;
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          init_date: moment(),
          end_date: moment(),
          init_schedule: moment(),
          end_schedule: moment(),
          location: undefined,
          supervisor: undefined,
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          setLoading(true);
          createNewAssigment(values);
          resetForm();
        }}
      >
        {({ isSubmitting, resetForm }) => (
          <Spin spinning={loading} tip="Enviando...">
            <Form>
              <Row gutter={[8, 8]}>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Field
                    allowClear
                    style={{ width: '100%' }}
                    name="init_date"
                    label="Fecha inicio"
                    autoComplete="off"
                    component={AntDatePicker}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Field
                    allowClear
                    style={{ width: '100%' }}
                    name="init_schedule"
                    label="Horario"
                    autoComplete="off"
                    component={AntTimePicker}
                  />
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Field
                    allowClear
                    style={{ width: '100%' }}
                    name="end_date"
                    label="Fecha fin"
                    autoComplete="off"
                    component={AntDatePicker}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Field
                    allowClear
                    style={{ width: '100%' }}
                    name="end_schedule"
                    label="Horario"
                    autoComplete="off"
                    component={AntTimePicker}
                  />
                </Col>
              </Row>
              <Row>
                {locationList ? (
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Field
                      showSearch
                      allowClear
                      style={{ width: '100%' }}
                      name="location"
                      label="Locación"
                      component={AntSelect}
                      placeholder="Selecciona la locación"
                      selectOptions={() =>
                        locationList.map((item) => (
                          <Option label={item.name} key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))
                      }
                    />
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
              <Row>
                {supervisorList ? (
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Field
                      allowClear
                      showSearch
                      style={{ width: '100%' }}
                      name="supervisor"
                      label="Supervisor"
                      component={AntSelect}
                      placeholder="Selecciona el supervisor"
                      selectOptions={() =>
                        supervisorList.map((item) => (
                          <Option
                            label={item.name + ' ' + item.last_name}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name + ' ' + item.last_name}
                          </Option>
                        ))
                      }
                    />
                  </Col>
                ) : (
                  <> </>
                )}
              </Row>
              <Row justify="end">
                <Col>
                  <Button htmlType="submit">Crear</Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}
export default connect(mapStateToProps)(AssigmentForm);
