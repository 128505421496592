import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import Content from '../../../components/Message/Content.jsx';
import '../styles/styles.css';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { messages } from '../../../services/messages.js';
import { loginTypes } from '../../../constants/login-types.js';

const MessageContent = ({ ...props }) => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [read, setReaded] = useState();
  const [attachment, setAttachment] = useState([]);

  const readed = async () => {
    const data = {
      id_message: id,
      id_user: props.user.user_id,
    };
    try {
      const response = await messages.messageReaded(data);
      setData(response.data.data);
      stringToArray(response.data.data.attachment)

      countUnreadedMessages();
      return response;
    } catch (error) {
      throw error;
    }
  };
  const stringToArray = (string) => {
    let array = string.split(',');
    setAttachment(array);
  };
  const countUnreadedMessages = async () => {
    const data = {
      id_user: props.user.user_id,
    };
    try {
      const response = await messages.getCountUnreadedMessage(data);
      props.setCountUnreadedMessage(response.data);
    } catch (error) {
      throw error;
    }
  };

  const userReadedList = async () => {
    const data = {
      id_message: id,
    };
    try {
      const response = await messages.messageReadedList(data);
      setReaded(response.data.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    readed();
    userReadedList();
  }, []);

  return (
    <div>
      <Content data={data} list={read} attachment={attachment} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCountUnreadedMessage: (unreaded) =>
    dispatch({ type: loginTypes.COUNT_UNREADED_MESSAGE, unreaded }),
});

function mapStateToProps(state) {
  const { user } = state.login;
  return {
    user: state.login.user,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MessageContent);
