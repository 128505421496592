import { Button, Col, Row, Select } from 'antd';
import React, { useState } from 'react';
import './styles/styles.css';
import { Field, Form, Formik } from 'formik';
import { AntSelect } from '../AntFields/AntFields';
import openNotification from '../Notificaciones';
import { userService } from '../../services/user-services';
import * as Yup from 'yup';

const FormAddHotel = ({ hotels, channelId, setIsOpenAddHotel, setRefresh }) => {

  const handleSubmit = async (value) => {
    const data = {
      id_canal: channelId,
      hotel: value.hotel,
    };
    try {
      const response = await userService.addHotelToChannel(data);
      openNotification('success', 'Hotel agregado', true);
      setIsOpenAddHotel(false);
      setRefresh(true)
      return response;
    } catch (error) {
      openNotification('error', 'Error al agregar el hotel', true);
      setIsOpenAddHotel(false);
      return error;
    }
  };


  const schema = Yup.object().shape({
    hotel: Yup.number().required('Requerido'),
  });

  return (
    <div>
      <Formik
        initialValues={{
          hotel: '',
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            <Field
              allowClear
              style={{ width: '100%' }}
              name="hotel"
              label="Hotel"
              autoComplete="off"
              component={AntSelect}
              placeholder="Selecciona un hotel"
              optionFilterProp="label"
              showSearch
              selectOptions={() =>
                hotels.map((item) => (
                  <Option label={item.name} key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))
              }
            />
            <Row justify="end">
              <Col>
                <Button htmlType="submit">Agregar</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default FormAddHotel;
