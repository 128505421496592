import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { some } from 'lodash';
import { connect } from 'react-redux';
import Navbar from '../Menu';
import { CountDown } from '../Timer';
import './_index.styl';

const { Header, Content, Footer } = Layout;
const MainLayout = (props) => (
  <Layout style={{ minHeight: '100vh' }}>
    <div className="header__wrap">
      <Header className="header">
        <Navbar location={props.location} />
        <div className="header__counter">
          <CountDown />
        </div>
      </Header>
      {/* Si es un vendedor  y verificar si existe concesion*/}
      {props.user.concesion &&
        some(props.user.roles, ['name', 'TOURSALES']) &&
        props.user.roles.length == 1 && (
          <div className="header__caption">
            <div className="header__caption__wrap">
              <span>
                {props.user.concesion.hotel && <>Hotel: {props.user.concesion.hotel.name} </>}/
                Zona: {props.user.concesion.locationZone}
              </span>
            </div>
          </div>
        )}
    </div>
    <Content className="content">{props.children}</Content>
    <Footer className="footer">
      <span>Caribe Maya© | Derechos Reservados</span>
    </Footer>
  </Layout>
);
const mapStateToProps = (state) => {
  const { login } = state;
  const { user } = login;
  return {
    user,
  };
};
const ConnectedMainLayout = connect(mapStateToProps)(MainLayout);

export default withRouter(ConnectedMainLayout);
