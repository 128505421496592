/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { get, isFunction } from 'lodash';
import {
  DatePicker,
  Form,
  Input,
  TimePicker,
  Select,
  Checkbox,
  AutoComplete,
  InputNumber,
} from 'antd';
import FormGroup from '../WrapInputs/Wrapper';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const { Search } = Input;
const { RangePicker } = DatePicker;

const CreateAntField =
  (AntComponent) =>
  ({
    field,
    inputData,
    form,
    hasFeedback,
    label,
    selectOptions,
    type,
    noMargin,
    validateNumber,
    lugarPickupService,
    updateLocation,
    provider,
    fechaService,
    callback,
    callbackForm,
    clear,
    ...props
  }) => {
    const touched = get(form.touched, field.name);
    const submitted = form.submitCount > 0;
    const hasError = get(form.errors, field.name);
    // const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { value } }) => {
      form.setFieldValue(field.name, value);
      if (callback && typeof callback === 'function') {
        callback(value);
      }
    };
    const onChange = (value, option) => {
      if (typeof lugarPickupService === 'function') {
        lugarPickupService(value);
      }
      if (typeof updateLocation === 'function') {
        updateLocation(value);
      }
      if (typeof fechaService === 'function') {
        fechaService(value, form);
      }
      if (typeof clear === 'function') {
        clear(form);
      }
      if (callback && typeof callback === 'function') {
        callback(option);
      }
      if (typeof provider === 'function') {
        provider(value, form);
      }

      if (callbackForm && typeof callbackForm === 'function') {
        callbackForm(option, form);
      }
      form.setFieldValue(field.name, value);
    };
    // const onBlur = () => form.setFieldTouched(field.name, true);
    return (
      <FormGroup label={label} noMargin={noMargin}>
        <FormItem
          hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
          help={submittedError || touchedError ? hasError : false}
          validateStatus={submittedError || touchedError ? 'error' : 'success'}
        >
          {type == 'password' ? (
            <AntComponent.Password
              {...field}
              {...props}
              onChange={type ? onInputChange : onChange}
            />
          ) : (
            <AntComponent
              {...field}
              {...props}
              onChange={type ? onInputChange : onChange}
              optionFilterProp="label"
            >
              {selectOptions
                ? isFunction(selectOptions)
                  ? selectOptions()
                  : selectOptions.map((item) => {
                      if (typeof item === 'object') {
                        return (
                          <Option
                            label={item.name}
                            key={item.oaci ? item.oaci : item.id}
                            value={item.oaci ? item.oaci : item.id}
                          >
                            {item.name}
                          </Option>
                        );
                      }
                      return (
                        <Option label={item} key={item} value={item}>
                          {item}
                        </Option>
                      );
                    })
                : null}
            </AntComponent>
          )}
        </FormItem>
      </FormGroup>
    );
  };
const CreateCheckBox =
  (AntComponent) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    partial,
    // value,
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = form.submitCount > 0;
    const hasError = get(form.errors, field.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { checked, value } }) => {
      if (value) {
        form.setFieldValue(field.name, value);
      } else {
        form.setFieldValue(field.name, checked);
      }
      if (partial) {
        partial(checked);
      }
    };
    return (
      <div>
        <FormItem
          style={{ margin: 0 }}
          hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
          help={submittedError || touchedError ? hasError : false}
        >
          <AntComponent {...field} {...props} onChange={onInputChange}>
            {label}
          </AntComponent>
        </FormItem>
      </div>
    );
  };
const CreateCheckBoxTwo =
  (AntComponent) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    partial,
    // value,
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = form.submitCount > 0;
    const hasError = get(form.errors, field.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { checked, value } }) => {
      if (value) {
        form.setFieldValue(field.name, checked);
      } else {
        form.setFieldValue(field.name, checked);
      }
      if (partial) {
        partial(checked);
      }
    };
    return (
      <div>
        <FormItem
          style={{ margin: 0 }}
          hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
          help={submittedError || touchedError ? hasError : false}
        >
          <AntComponent {...field} {...props} onChange={onInputChange}>
            {label}
          </AntComponent>
        </FormItem>
      </div>
    );
  };

const CreateCheckBoxcancelaciones =
  (AntComponent) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    partial,
    // value,
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = form.submitCount > 0;
    const hasError = get(form.errors, field.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { checked, value } }) => {
      if (value) {
        form.setFieldValue(field.name, value);
      } else {
        form.setFieldValue(field.name, checked);
      }

      if (checked) {
        /* form.setFieldValue(field.name, value); */
        console.log('activo');
      } else {
        form.setFieldValue('amount', null);
        form.setFieldValue('refund_packages', null);
        form.setFieldValue('refund_transporte', null);
        form.setFieldValue('refund_tour', null);
        form.setFieldValue('refund_tickets', null);
        form.setFieldValue('refund_cars', null);
        form.setFieldValue('refund_discs', null);
        /* form.setFieldValue('currency', null); */

        /*   console.log(value);
        console.log(field.name); */
        console.log('desactivo');
      }

      if (partial) {
        partial(checked);
      }
    };
    return (
      <div>
        <FormItem
          style={{ margin: 0 }}
          hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
          help={submittedError || touchedError ? hasError : false}
        >
          <AntComponent {...field} {...props} onChange={onInputChange}>
            {label}
          </AntComponent>
        </FormItem>
      </div>
    );
  };
const CreateAutoComplete =
  (AntComponent) =>
  ({ field, form, hasFeedback, label, ...props }) => {
    const touched = form.touched[field.name];
    const submitted = form.submitCount > 0;
    const hasError = get(form.errors, field.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onSelect = (value) => {
      form.setFieldValue(field.name, value);
    };
    const onChange = (value) => {
      form.setFieldValue(field.name, value);
    };
    return (
      <div>
        <FormItem
          hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
          help={submittedError || touchedError ? hasError : false}
        >
          <AntComponent {...field} {...props} onSelect={onSelect} onChange={onChange} />
        </FormItem>
      </div>
    );
  };
export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntRangePicker = CreateAntField(RangePicker);
export const AntInput = CreateAntField(Input);
export const AntInputSearch = CreateAntField(Search);
export const AntInputNumber = CreateAntField(InputNumber);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntTextArea = CreateAntField(TextArea);
export const AntCheckBox = CreateCheckBox(Checkbox);
export const AntCheckBoxTwo = CreateCheckBoxTwo(Checkbox);
export const AntCheckBoxcancelar = CreateCheckBoxcancelaciones(Checkbox);
export const AntAutocomplete = CreateAutoComplete(AutoComplete);
