import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import './styles/styles.css';
import FormAddLocation from './FormAddLocation';
import { userService } from '../../services/user-services';
import openNotification from '../Notificaciones';
const { confirm } = Modal;

const TableHotel = ({ data, setRefresh }) => {
  const [isOpenModalLocation, setIsOpenModalLocation] = useState(false);
  const [hotelId, setHotelId] = useState();

  const columns = [
    {
      title: <span className="channel-font-table-title">Hoteles</span>,
      width: '100%',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <span className="channel-font-table-title">Locaciones</span>,
      key: 'locacion',
      align: 'center',
      render: (_, record) => (
        <Button
          type="link"
          icon={<EditOutlined />}
          size="large"
          onClick={() => addLocation(record)}
        />
      ),
    },
    {
      title: <span className="channel-font-table-title">Act/Desc</span>,
      key: 'status',
      align: 'center',
      render: (_, record) => (
        <Switch checked={record.status} onChange={() => statusChanged(record.id)} />
      ),
    },
    {
      title: <span className="channel-font-table-title">Eliminar</span>,
      key: 'delete',
      align: 'center',
      render: (_, record) => (
        <Button
          type="link"
          icon={<DeleteOutlined />}
          size="large"
          danger
          onClick={() => deleteHotel(record)}
        />
      ),
    },
  ];

  const statusChanged = async (item) => {
    console.log(item);
    try {
      const response = await userService.changeStatusLocation(item);
      openNotification('success', 'Estatus del hotel cambiado', true);
      setRefresh(true);
      return response;
    } catch (error) {
      openNotification('error', 'Error al realizar cambio de estatus', true);
      return error;
    }
  };

  const addLocation = (item) => {
    setIsOpenModalLocation(true);
    setHotelId(item.id);
  };

  const deleteHotel = (item) => {
    confirm({
      title: '¿Esta seguro de eliminar este hotel?',
      icon: <ExclamationCircleOutlined />,
      content: ``,
      onOk() {
        Promise.all([userService.deleteHotelToChannel(item.id)])
          .then((response) => {
            openNotification('success', 'Hotel eliminado', true);
            setRefresh(true);
            return response.data;
          })
          .catch((error) => {
            setRefresh(true);
            openNotification('error', `No se pudo eliminar el hotel`, true);
            return error;
          });
      },
      onCancel() {},
    });
  };

  const handleCancel = () => {
    setIsOpenModalLocation(false);
  };

  return (
    <>
      <div>
        <Table className="channel-table-bg channel-pagination-bg" columns={columns} dataSource={data} />
      </div>
      <div>
        <Modal
          title={
            <span className="channel-font-label channel-font-size-label">Agregar locación</span>
          }
          visible={isOpenModalLocation}
          onCancel={handleCancel}
          footer={null}

        >
          <FormAddLocation hotelId={hotelId} />
        </Modal>
      </div>
    </>
  );
};
export default TableHotel;
