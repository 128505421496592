import React, { useEffect, useState } from 'react';
import { messages } from '../../../services/messages';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LoginWhatsApp = ({ qrCodeURL, active }) => {
  return (
    <div className='display-justify-center'>
      {/* <button onClick={getQR}>qr</button> */}
      <Spin
        spinning={active}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      />
      {!active && (
        <div >
          <img src={`${qrCodeURL}`} />
        </div>
      )}
    </div>
  );
};
export default LoginWhatsApp;
