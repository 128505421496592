// fallback de 30 minutos
function startTimer(baseTime = 30 * 60) {
  return {
    type: 'START_TIMER',
    baseTime,
    now: new Date().getTime(),
  };
}

function stopTimer() {
  return {
    type: 'STOP_TIMER',
    now: new Date().getTime(),
  };
}

function resetTimer() {
  return {
    type: 'RESET_TIMER',
    now: new Date().getTime(),
  };
}

export const timeActions = {
  startTimer,
  resetTimer,
  stopTimer,
};
