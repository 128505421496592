import React from 'react';
import { Badge, Col, Menu, Row } from 'antd';
import './styles.styl';
// import { includes } from 'lodash';
import Modal from '../Modal';
import ConsesionForm from '../Forms/consesion';
import { connect } from 'react-redux';
import { some } from 'lodash';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;
const RightMenu = ({ type, location, userName, ...props }) => {
  const isAfiliado = some(props.roles, ['name', 'AFILIADO']); // Verificar o elminar
  const isAffiliated = some(props.roles, ['name', 'AFFILIATEDDASHBOARD']);
  const isProveedor = some(props.roles, ['name', 'PROVEEDORES']);
  const isAdmin = some(props.roles, ['name', 'ADMIN']);
  const isGteConcesion = some(props.roles, ['name', 'GERENTE CONCESION']);
  const isRepConcesion = some(props.rolesNuevos, ['name', 'REP CONCESION']);
  const isSuperConcesion = some(props.rolesNuevos, ['name', 'SUPERVISOR CONCESION']);
  const isRepOficial = some(props.rolesNuevos, ['name', 'REP OFFICIAL']);

  return (
    <div style={{ display: 'flex' }}>
      {/* {props.roles && includes(props.roles, 'TOURSALES') && (
        <div
          className="visible-md"
          style={{ display: 'inline-block', lineHeight: '64px', color: '#fff' }}
        >
          <span style={{ position: 'relative' }} className="icon icon-user">
            &nbsp; ({props.user.userName})
          </span>
        </div>
      )} */}
      <Menu
        mode={type || 'horizontal'}
        theme={type ? 'dark' : ''}
        selectedKeys={[location.pathname]}
        className="custom_menu"
      >
        <SubMenu
          key="perfil"
          title={
            <div>
              {props.unreaded > 0 ? (
                <Badge dot={true}>
                  <div>
                    <span className="icon icon-user"> {props.user.userName}</span>
                  </div>
                </Badge>
              ) : (
                <div>
                  <span className="icon icon-user"> {props.user.userName}</span>
                </div>
              )}
            </div>
          }
        >
          {!isAfiliado && !isAffiliated && (
            <Menu.Item key="consesion" style={{ padding: 0 }}>
              <Modal
                customButton="Cambiar hotel y locación"
                destroyOnClose
                maskClosable={false}
                closable={false}
                width={1000}
              >
                <ConsesionForm title="Cambiar hotel y locación" update />
              </Modal>
            </Menu.Item>
          )}
          {isAdmin || isGteConcesion || isRepConcesion || isSuperConcesion || isRepOficial ? (
            <Menu.Item key="mensage" style={{ padding: 0 }}>
              <Link to={`/mensaje`}>
                <Row
                  style={{
                    padding: '0px 1rem',
                    cursor: 'pointer',
                    width: '100%',
                    textAlign: 'left',
                  }}
                >
                  <Col sm={11} md={11} lg={10}>
                    Mensajes
                  </Col>
                  {props.unreaded > 0 && (
                    <Col sm={1} md={1} lg={2}>
                      <Badge count={props.unreaded}></Badge>
                    </Col>
                  )}
                </Row>
              </Link>
            </Menu.Item>
          ):(
            <></>
          )}

          <Menu.Item key="salir" style={{ padding: 0 }}>
            <button
              style={{
                padding: '0px 1rem',
                cursor: 'pointer',
                width: '100%',
                textAlign: 'left',
              }}
              onClick={() => props.logout()}
            >
              Salir
            </button>
          </Menu.Item>
        </SubMenu>
        {/* {((props.roles && includes(props.roles, 'ADMIN')) ||
            includes(props.roles, 'MANAGER') ||
            includes(props.roles, 'SUPERVISOR')) && (
            <SubMenu key="admin" title="Administrador">
              <Menu.Item key="salir" style={{ padding: 0 }}>
                <button
                  style={{ padding: '0px 1rem', cursor: 'pointer', width: '100%', textAlign: 'left' }}
                >
                  Opciones de Administrador
                </button>
              </Menu.Item>
            </SubMenu>
          )} */}
        <SubMenu key="sub3333" title="Idioma">
          <Menu.Item key="7777">Español</Menu.Item>
          <Menu.Item key="8888">Inglés</Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
};

function mapStateToProps(state) {
  const { user, showModalConsesion } = state.login;
  return {
    user_id: user.user_id,
    roles: user.roles,
    rolesNuevos: user.rolesNuevos,
    unreaded: user.unreadedMessage,
  };
}

const ConnectedCheckOut = connect(mapStateToProps)(RightMenu);
export default ConnectedCheckOut;
