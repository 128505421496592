import { history } from '../../store/history';
import store from '../../store/store';
import { userActions } from '../../actions/user-actions';

export default function handleResponse(error) {
  if (error.response) {
    if (error.response.status === 401) {
      store.dispatch(userActions.logout());
      history.push('/');
    }
    return Promise.reject(error.response.data);
  }
  if (error.request) {
    return Promise.reject(error.request);
  }
  return Promise.reject(error.message);
}
