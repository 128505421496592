import { axiosApi, API_BACK, API_SOCKET } from '../conf/configurationProd';
import authHeader from './utils/authHeader';
import handleResponse from './utils/handleResponse';

function getMessageList() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}messages`, { ...requestOptions })
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function getMessageByidUser(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}messages/get-by-id-user`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function createMessage(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}messages/create`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function messageReaded(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}messages/update-message-read`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function deleteMessage(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}messages/delete`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function messageReadedList(data) {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}messages/get-readed-messages`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function getAllRoles() {
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .get(`${API_BACK}main-roles`, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}

function getCountUnreadedMessage(data) {
    const requestOptions = {
        headers: authHeader(),
      };
      return axiosApi
        .post(`${API_BACK}messages/get/count-unreaded-messages`, data, requestOptions)
        .then((response) => response)
        .catch((error) => handleResponse(error));
}

function getReps(data){
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_BACK}messages/get/rep`, data, requestOptions)
    .then((response) => response)
    .catch((error) => handleResponse(error));
}
function getGroupChats(data){
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_SOCKET}whatsapp/getGroupChats`, data, requestOptions)
    .then((response) => response.data)
    .catch((error) => handleResponse(error));
}
function doWhatsappLogout(data){
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_SOCKET}whatsapp/logout`, data, requestOptions)
    .then((response) => response.data)
    .catch((error) => handleResponse(error));
}
function createWhatsappMessage(data){
  const requestOptions = {
    headers: authHeader(),
  };
  return axiosApi
    .post(`${API_SOCKET}whatsapp/sendMessage`, data, requestOptions)
    .then((response) => response.data)
    .catch((error) => handleResponse(error));
}
export const messages = {
  getMessageList,
  getMessageByidUser,
  createMessage,
  messageReaded,
  deleteMessage,
  messageReadedList,
  getAllRoles,
  getCountUnreadedMessage,
  getReps,
  doWhatsappLogout,
  createWhatsappMessage,
  getGroupChats
};
