import React from 'react';
import { Form, Field } from 'formik';
import { Row, Col, Button } from 'antd';
import { AntInput } from '../AntFields/AntFields';

export default ({ handleSubmit, submitCount, isSubmitting, errors, error }) => {
  return (
    <Form className="form-container" onSubmit={handleSubmit}>
      {errors && errors.error && (
        <div className="text-center mb-10">
          <strong>{errors.error}</strong>
        </div>
      )}
      <Row type="flex" align="middle" gutter={16} justify="center">
        <Col xs={12} sm={10}>
          <Field
            component={AntInput}
            name="user"
            size="large"
            type="text"
            placeholder="Usuario"
            hasFeedback
            noMargin
            style={{ marginBottom: 10 }}
          />
        </Col>
        <Col xs={12} sm={10}>
          <Field
            component={AntInput}
            name="password"
            type="password"
            size="large"
            placeholder="Contraseña"
            hasFeedback
            noMargin
            style={{ marginBottom: 10 }}
          />
        </Col>
        <Col xs={12} sm={10}>
          <Button
            disabled={isSubmitting}
            style={{ marginTop: 10 }}
            size="large"
            htmlType="submit"
            type="primary"
            block
          >
            Iniciar Sesión
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
