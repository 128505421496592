import { ArrowLeftOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, List, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles/styles.css';
import { connect } from 'react-redux';
import { some } from 'lodash';
import { CDN } from '../../conf/configurationProd';

const Container = ({ data, list, attachment, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isAdmin = some(props.roles, ['name', 'ADMIN']);
  const isGteConcesion = some(props.roles, ['name', 'GERENTE CONCESION']);

  const formatDate = (date) => {
    const formatted = moment(date).format('DD/MM/YYYY hh:mm A');
    return formatted;
  };
  const openDrawer = () => {
    setIsOpen(true);
  };
  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <>
      {data != undefined && (
        <div>
          <div className="message-card-border-top">
            <Row className="message-container-top">
              <Col xs={6} sm={6} md={6} lg={6}>
                <Tooltip title="Volver a pagina principal">
                  <Link to={`/mensaje`}>
                    <Button style={{ border: 0 }} icon={<ArrowLeftOutlined />}>
                      Atras
                    </Button>
                  </Link>
                </Tooltip>
              </Col>
              {isAdmin || isGteConcesion ? (
                <Col className="display-justify-end" xs={6} sm={6} md={6} lg={6}>
                  <Tooltip title="Ver usuarios que leyeron este mensaje">
                    <Button
                      icon={
                        <span>
                          <TeamOutlined />
                        </span>
                      }
                      onClick={() => openDrawer()}
                    >
                      Confirmación de lectura
                    </Button>
                  </Tooltip>
                  {list != undefined && (
                    <Drawer
                      width={400}
                      title={<span className="message-drawer-readed-title">Leído por:</span>}
                      placement="right"
                      closable={true}
                      onClose={closeDrawer}
                      visible={isOpen}
                    >
                      <div>
                        <Row className="message-drawer-readed-subtitle">
                          <Col md={6} lg={6}>
                            <span>Nombre</span>
                          </Col>
                          <Col className="display-justify-end" md={6} lg={6}>
                            <span>Fecha de lectura</span>
                          </Col>
                        </Row>
                        <List
                          itemLayout="horizontal"
                          dataSource={list.readed}
                          renderItem={(item) => (
                            <List.Item>
                              <List.Item.Meta
                                title={
                                  <span>
                                    {item.name} {item.last_name}
                                  </span>
                                }
                              />
                              <div>{formatDate(item.updated_at)}</div>
                            </List.Item>
                          )}
                        />
                        <div className="message-drawer-readed-pt">
                          <span className="message-drawer-readed-title">Entregado a:</span>
                          <Divider></Divider>
                          <Row>
                            <Col xs={6} sm={6} md={6} lg={6}>
                              <span className="message-drawer-readed-subtitle ">Nombre</span>
                            </Col>
                          </Row>
                          <List
                            itemLayout="horizontal"
                            dataSource={list.notReaded}
                            renderItem={(item) => (
                              <List.Item>
                                <List.Item.Meta
                                  title={
                                    <span>
                                      {item.name} {item.last_name}
                                    </span>
                                  }
                                />
                                <div>{formatDate(item.created_at)}</div>
                              </List.Item>
                            )}
                          />
                        </div>
                      </div>
                    </Drawer>
                  )}
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>

          <div className="messsage-card message-card-border-bottom">
            <Row className="message-container">
              <Col xs={6} sm={6} md={6} lg={6} className="messsage-card-title">
                <span>{data.subject}</span>
              </Col>
              <Col className="display-justify-end" xs={6} sm={6} md={6} lg={6}>
                <span>{formatDate(data.created_at)}</span>
              </Col>
            </Row>
            <div className="messsage-card-content message-container">
              <div>
                <span>{data.message_content}</span>
              </div>
              {attachment.length > 0 ? (
                <div>
                  <Divider>
                    <span className="message-card-attach-title-bold">Adjuntos</span>
                  </Divider>
                  <div>
                    {attachment.map((item, index) => (
                      <div>
                        <a target="_blank" href={`${CDN}${data.directory}/${item}`} key={index}>
                          {' '}
                          {item}{' '}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  const { user } = state.login;
  return {
    user_id: user.user_id,
    roles: user.roles,
  };
};
export default connect(mapStateToProps)(Container);
