import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Space, Select, Spin, notification, Popconfirm, Empty } from 'antd';
import { connect } from 'react-redux';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { AntInput, AntSelect } from '../../AntFields/AntFields';
import Card from '../../Card';
import { loginTypes } from '../../../constants';
import { reserva } from '../../../services/reservacion';
import OpenNotification from '../../Notificaciones';
import moment from 'moment';
import { some } from 'lodash';

const schema = Yup.object().shape({
  hotel: Yup.string().required('Requerido'),
  location: Yup.string().required('Requerido'),
  channel: Yup.string().required('Requerido'),
});

const { Option } = Select;

const ConsesionForm = ({ title, update, ...props }) => {
  console.log(props)
  const [hoteles, setHoteles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locaciones, setLocaciones] = useState([]);
  const [Data, setData] = useState([]);
  const [reps, setReps] = useState([]);
  const [search, setSearch] = useState(false);
  const [agency, setAgency] = useState(null);
  const [initalVal, setInitialVal] = useState({
    services: undefined,
    location: undefined,
    channel: props.channel.id,
  })
  const isVendedor = some(props.roles, ['name', 'TOURSALES']);
  const isAdmin = some(props.roles, ['name', 'ADMIN']);
  const isSuperAdmin = some(props.roles, ['name', 'SUPER ADMIN']);
  const isGteConcesion = some(props.roles, ['name', 'GERENTE CONCESION']);
  const isProveedor = some(props.roles, ['name', 'PROVEEDORES']);
  const isAfiliado = some(props.roles, ['name', 'AFILIADO']);
  useEffect(() => {
    reserva.hoteles(props.channel.id).then((res) => {
      setHoteles(res.data);
      setLoading(false);
    });
    reserva.reps().then((res) => {
      setReps(res.data);
    });
    if (props.setRepLocation) {
      setInitialVal({
        hotel: props.setRepLocation.hotel,
        location: props.setRepLocation.location,
        channel: props.channel.id,
      })
      reserva.locaciones(props.setRepLocation.hotel, props.user_id).then((res) => {
        setData([]);
        setLocaciones(res.data);
      });
    }
  }, []);

  return isVendedor == true && isAdmin != true ? (
    <Card title={title || ''}>
      <Formik
        initialValues={initalVal}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          if (agency != null) {
            const datos = {
              datos: values,
              user_id: props.user_id,
              agency,
            };
            reserva.locacionUser(datos).then((res) => {
              OpenNotification('success', 'Ubicación actualizada correctamente.', true);
              props.closemodal();
              props.changeConsecion(res.data);
              props.changeChannel(res.data.canal);
              setAgency(null)
            });
          } else {
            setSearch(true);
            const date = new Date();
            const primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
            const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            const dataRequest = {
              from: primerDia,
              to: ultimoDia,
              hotel: values.hotel,
              locaciones: [values.location],
              canal: values.channel,
            };
            setData([]);
            reserva.verPresupuestos(dataRequest).then((res) => {

              setData(res.data);
            });
          }

        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => (
          <Form>
            <Spin spinning={loading}>
              <Row type="flex" gutter={16} justify="center">
                {/* Debe ser mayor a 1 */}
                {props.channels.length > 1 && (
                  <Col xs={12} lg={4}>
                    <Field
                      name="channel"
                      label="Canal"
                      component={AntSelect}
                      placeholder="Seleccione una opción"
                      size="large"
                      selectOptions={() =>
                        props.channels.map((item) => (
                          <Option label={item.canal} key={item.id} value={item.id}>
                            {item.canal}
                          </Option>
                        ))
                      }
                      callback={(value) => {
                        setFieldValue('hotel', []);
                        setFieldValue('location', []);
                        setData([]);
                        reserva.hoteles(value.key).then((res) => {
                          setHoteles(res.data);
                          setLoading(false);
                        });
                      }}
                      optionFilterProp="label"
                    />
                  </Col>
                )}
                <Col xs={12} lg={props.channels.length > 1 ? 4 : 5}>
                  <Field
                    name="hotel"
                    size="large"
                    component={AntSelect}
                    placeholder="Seleccione una opción"
                    label="Seleccione Hotel"
                    selectOptions={() =>
                      hoteles.map((item) => (
                        <Option label={item.name} key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))
                    }
                    callback={(value) => {
                      reserva.locaciones(value.key, props.user_id).then((res) => {
                        setFieldValue('location', []);
                        setData([]);
                        setLocaciones(res.data);
                        if (value.label == 'TTOO') {
                          setAgency(value.label);
                        } else {
                          setAgency(null);
                        }
                      });
                    }}
                    showSearch
                    optionFilterProp="label"
                  />
                </Col>
                <Col xs={12} lg={props.channels.length > 1 ? 4 : 5}>
                  <Field
                    name="location"
                    label="Seleccione Locación"
                    component={AntSelect}
                    placeholder="Seleccione una opción"
                    size="large"
                    disabled={locaciones.length == 0}
                    selectOptions={() =>
                      locaciones.map((item) => (
                        <Option label={item.name} key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))
                    }
                    callback={(value) => {
                      setData([]);
                    }}
                    showSearch
                    optionFilterProp="label"
                  />
                </Col>
              </Row>
              <Row type="flex" gutter={16}>
                <Col xs={12} className="text-center mb-15">
                  <Space>
                    <Button className="btn-fix-width" type="primary" size="large" htmlType="submit">
                      Confirmar Locación
                    </Button>
                    {update && (
                      <Button
                        className="btn-fix-width"
                        type="default"
                        size="large"
                        htmlType="button"
                        onClick={() => {
                          props.closemodal();
                        }}
                      >
                        Cancelar
                      </Button>
                    )}
                  </Space>
                </Col>
              </Row>
            </Spin>
          </Form>
        )}
      </Formik>
      {Data && Data.length > 0 ? (
        <>
          <Formik
            initialValues={{
              presupuesto_id: Data[0].id,
              fechas_locacion: [moment().format('Y-MM-DD')],
              channel: Data[0].canal_id,
              hotel: Data[0].sales_place_id,
              location: Data[0].sales_location_id,
              presupuesto: '$ ' + Data[0].presupuesto_diario + ' USD',
              rep_id: props.user_id,
            }}
            // validationSchema={schema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              reserva
                .repLocacionPresupuesto(values)
                .then((res) => {
                  notification.success({
                    message: res.data.message,
                    duration: 15,
                  });
                  if(res.data.alert && res.data.alert != ''){
                    notification.success({
                      message: 'Tienes más de una locación asignada',
                      description:res.data.alert,
                      duration:1000
                    })
                  }
                  const datos = {
                    datos: values,
                    user_id: props.user_id,
                    agency,
                  };
                  reserva.locacionUser(datos).then((res) => {
                    OpenNotification('success', 'Ubicación actualizada correctamente.', true);
                    props.closemodal();
                    props.changeConsecion(res.data);
                    props.changeChannel(res.data.canal);
                  });
                })
                .catch((error) => {
                  const mensajeError = error.message;
                  notification.error({
                    message: mensajeError,
                    duration: 15,
                  });
                  const datos = {
                    datos: values,
                    user_id: props.user_id,
                    agency,
                  };
                  reserva.locacionUser(datos).then((res) => {
                    OpenNotification('success', 'Ubicación actualizada correctamente.', true);
                    props.closemodal();
                    props.changeConsecion(res.data);
                    props.changeChannel(res.data.canal);
                  });
                });
            }}
          >
            {({ values, touched, errors, isSubmitting, setFieldValue, submitForm }) => (
              <Form>
                <Spin spinning={loading}>
                  <Row type="flex" gutter={16} justify="center">
                    <Col xs={12} lg={6}>
                      <Field
                        name="rep_id"
                        label="Tu eres el Representante:"
                        size="large"
                        component={AntSelect}
                        disabled
                        selectOptions={() =>
                          reps.map((item) => (
                            <Option label={item.name} key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))
                        }
                        showSearch
                        optionFilterProp="label"
                      />
                    </Col>
                  </Row>
                  <Row type="flex" gutter={16} justify="center">
                    <Col xs={12} lg={2}>
                      <Field
                        name="channel"
                        label="Canal"
                        component={AntSelect}
                        disabled
                        size="large"
                        selectOptions={() =>
                          props.channels.map((item) => (
                            <Option label={item.canal} key={item.id} value={item.id}>
                              {item.canal}
                            </Option>
                          ))
                        }
                        optionFilterProp="label"
                      />
                    </Col>
                    <Col xs={12} lg={3}>
                      <Field
                        name="hotel"
                        size="large"
                        component={AntSelect}
                        label="Hotel del presupuesto"
                        disabled
                        selectOptions={() =>
                          hoteles.map((item) => (
                            <Option label={item.name} key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))
                        }
                        showSearch
                        optionFilterProp="label"
                      />
                    </Col>
                    <Col xs={12} lg={4}>
                      <Field
                        name="location"
                        label="Locación de presupuesto"
                        component={AntSelect}
                        size="large"
                        disabled
                        selectOptions={() =>
                          locaciones.map((item) => (
                            <Option label={item.name} key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))
                        }
                        showSearch
                        optionFilterProp="label"
                      />
                    </Col>
                    <Col xs={12} lg={2}>
                      <Field
                        name="presupuesto"
                        label="Presupuesto USD"
                        component={AntInput}
                        size="large"
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row type="flex" gutter={16}>
                    <Col xs={12} className="text-center mb-15">
                      <Space>
                        <Popconfirm
                          title={() => {
                            return (
                              '¿Estas seguro de que eres ' +
                              reps
                                .filter((i) => i.id == props.user_id)
                                .map((item) => (item.id == props.user_id ? item.name : undefined)) +
                              ' mostrado con el presupuesto y  con las locaciones seleccionadas?'
                            );
                          }}
                          onConfirm={() => {
                            submitForm();
                          }}
                          okText="Sí"
                          cancelText="No"
                        >
                          <Button
                            className="btn-fix-width"
                            type="primary"
                            size="large"
                            htmlType="submit"
                            disabled={isSubmitting}
                            style={{ marginTop: '90px' }}
                          >
                            Guardar
                          </Button>
                        </Popconfirm>
                        {update && (
                          <Button
                            className="btn-fix-width"
                            type="default"
                            size="large"
                            htmlType="button"
                            onClick={() => {
                              props.closemodal();
                            }}
                            style={{ marginTop: '90px' }}
                          >
                            Cancelar
                          </Button>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Spin>
              </Form>
            )}
          </Formik>
        </>
      ) : search ? (
        <>
          <Row>
            <Col span={12}>
              <Empty description="No hay Presupuesto asignado para esa locación"></Empty>
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </Card>
  ) : (
    <Card title={title || ''}>
      <Formik
        initialValues={{
          services: undefined,
          location: undefined,
          channel: props.channel.id,
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          const datos = {
            datos: values,
            user_id: props.user_id,
            agency,
          };
          reserva.locacionUser(datos).then((res) => {
            OpenNotification('success', 'Ubicación actualizada correctamente.', true);
            props.closemodal();
            props.changeConsecion(res.data);
            props.changeChannel(res.data.canal);
          });
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => (
          <Form>
            <Spin spinning={loading}>
              <Row type="flex" gutter={16} justify="center">
                {props.channels.length > 1 && (
                  <Col xs={12} lg={4}>
                    <Field
                      name="channel"
                      label="Canal"
                      component={AntSelect}
                      placeholder="Seleccione una opción"
                      size="large"
                      selectOptions={() =>
                        props.channels.map((item) => (
                          <Option label={item.canal} key={item.id} value={item.id}>
                            {item.canal}
                          </Option>
                        ))
                      }
                      callback={(value) => {
                        setFieldValue('hotel', []);
                        setFieldValue('location', []);
                        reserva.hoteles(value.key).then((res) => {
                          setHoteles(res.data);
                          setLoading(false);
                        });
                      }}
                      optionFilterProp="label"
                    />
                  </Col>
                )}
                <Col xs={12} lg={props.channels.length > 1 ? 4 : 5}>
                  <Field
                    name="hotel"
                    size="large"
                    component={AntSelect}
                    placeholder="Seleccione una opción"
                    label="Seleccione Hotel"
                    selectOptions={() =>
                      hoteles.map((item) => (
                        <Option label={item.name} key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))
                    }
                    callback={(value) => {
                      reserva.locaciones(value.key, props.user_id).then((res) => {
                        setFieldValue('location', []);
                        setLocaciones(res.data);
                        if (value.label == 'TTOO') {
                          setAgency(value.label);
                        } else {
                          setAgency(null);
                        }
                      });
                    }}
                    showSearch
                    optionFilterProp="label"
                  />
                </Col>
                <Col xs={12} lg={props.channels.length > 1 ? 4 : 5}>
                  <Field
                    name="location"
                    label="Seleccione Locación"
                    component={AntSelect}
                    placeholder="Seleccione una opción"
                    size="large"
                    disabled={locaciones.length == 0}
                    selectOptions={() =>
                      locaciones.map((item) => (
                        <Option label={item.name} key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))
                    }
                    showSearch
                    optionFilterProp="label"
                  />
                </Col>
              </Row>
              <Row type="flex" gutter={16}>
                <Col xs={12} className="text-center mb-15">
                  <Space>
                    <Button
                      className="btn-fix-width"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      Guardar
                    </Button>
                    {update && (
                      <Button
                        className="btn-fix-width"
                        type="default"
                        size="large"
                        htmlType="button"
                        onClick={() => {
                          props.closemodal();
                        }}
                      >
                        Cancelar
                      </Button>
                    )}
                  </Space>
                </Col>
              </Row>
            </Spin>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

// export default ConsesionForm;

const mapDispatchToProps = (dispatch) => ({
  changeConsecion: (concesion) => dispatch({ type: loginTypes.CHANGE_CONSECION, concesion }),
  changeChannel: (channel) => dispatch({ type: loginTypes.CHANGE_CHANNEL, channel }),
});

function mapStateToProps(state) {
  const { user } = state.login;
  console.log(user);
  return {
    user_id: user.user_id,
    channels: user.channels,
    channel: user.channel,
    roles: user.roles,
    setRepLocation: user.setRepLocation
  };
}

const connectedCart = connect(mapStateToProps, mapDispatchToProps)(ConsesionForm);
export default connectedCart;
