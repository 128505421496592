import { Button, Col, Row, Spin } from 'antd';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { AntDatePicker, AntSelect, AntTimePicker } from '../AntFields/AntFields';
import moment from 'moment';
import { dailyAssigmentRole } from '../../services/dailyAssigmentRole';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import openNotification from '../Notificaciones';

const formatDate = (date) => {
    let format = new moment(date);
    return format;
}
const formatTime = (date) => {
    let formatime = moment(date, 'HH:mm:ss');
    return formatime;
}
const AssigmentFormUpdate = ({data, supervisorList, setIsModalOpen, setRestart}) => {
    const [loading, setLoading] = useState(false);
    const initialValues = {
        date: formatDate(data.assigment.date),
        init_schedule: formatTime(data.assigment.init_schedule),
        end_schedule:  formatTime(data.assigment.end_schedule),
        supervisor: data.assigment.id_assigment_user
    }
    const updateAssigment = async(item) => {
        let payload = {
            id_assigment: data.assigment.id
        };
        if(data.assigment.date !== item.date.format('YYYY-MM-DD')){
            payload = {
                ...payload,
                date: item.date.format('YYYY-MM-DD'),
            }
        }
        if(data.assigment.init_schedule !== item.init_schedule.format('HH:mm:ss')){
            payload = {
                ...payload,
                init_schedule: item.init_schedule.format('HH:mm:ss'),
            }
        }
        if(data.assigment.end_schedule !== item.end_schedule.format('HH:mm:ss')){
            payload = {
                ...payload,
                end_schedule: item.end_schedule.format('HH:mm:ss'),
            }
        }
        if(data.assigment.id_assigment_user !== item.supervisor){
            payload = {
                ...payload,
                id_assigment_user: item.supervisor,
            }
        }
        console.log(payload, 'envio de data')
        try {
            const response = await dailyAssigmentRole.updateAssigment(payload);
            openNotification('success', 'Asignación actualizada', true);
            setIsModalOpen(false);
            setLoading(false);
            setRestart(true);
            return response;
        } catch (error) {
            openNotification('error', 'Error al actualizar la asignación', true);
            setIsModalOpen(false);
            setLoading(false);
            setRestart(true);
            return error;
        }
    }
    return (
    <div>
        <Formik
            initialValues={
                initialValues
            }
            enableReinitialize
            onSubmit={(values, { resetForm }) =>{
                setLoading(true);
                updateAssigment(values);
                resetForm();
            }}
        >
        {({isSubmitting, resetForm}) => (
            <Spin spinning={loading} tip="Enviando...">
            <Form>
                <Row gutter={[8,8]}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                    <Field 
                    allowClear
                    style={{ width: '100%' }}
                    name="date"
                    label="Fecha"
                    autoComplete="off"
                    component={AntDatePicker}
                    />
                    </Col>
                </Row>
                <Row gutter={[8,8]}>
                <Col xs={12} sm={6} md={6} lg={6}>
                    <Field 
                    allowClear
                    style={{ width: '100%' }}
                    name="init_schedule"
                    label="Horario inicio"
                    autoComplete="off"
                    component={AntTimePicker}
                    />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                    <Field 
                    allowClear
                    style={{ width: '100%' }}
                    name="end_schedule"
                    label="Horario fin"
                    autoComplete="off"
                    component={AntTimePicker}
                    />
                    </Col>
                </Row>
                <Row> 
                {supervisorList ? (
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Field
                        allowClear
                        style={{ width: '100%' }}
                        name="supervisor"
                        label="Supervisor"
                        component={AntSelect}
                        placeholder="Selecciona el supervisor"
                        selectOptions={() =>
                            supervisorList.map((item) => (
                                <Option
                                label={item.name + ' ' + item.last_name}
                                key={item.id}
                                value={item.id}
                                >
                                    {item.name + ' ' + item.last_name}
                                </Option>
                            ))
                        }
                        />
                    </Col>
                ) : (
                    <> </>
                )}
                </Row>
                <Row justify="end">
                    <Col>
                        <Button htmlType="submit">Actualizar</Button>
                    </Col>
                </Row>
            </Form>                 
            </Spin>
        )}
        </Formik>
    </div>
    );
};

export default AssigmentFormUpdate;