import filterTypes from '../constants/dashboard-filter-types';

export function setDateRange(dateInit, dateFin) {
  return { type: filterTypes.FILTER_RANGE_DATE, dateInit, dateFin };
}
export function setIVA(iva) {
  return { type: filterTypes.FILTER_IVA, iva };
}

export function setCurrency(currency) {
  return { type: filterTypes.FILTER_CURRENCY, currency };
}

export function setLocation(location) {
  return { type: filterTypes.FILTER_LOCATION, location };
}

export function setProvider(providers) {
  return { type: filterTypes.FILTER_PROVIDER, providers };
}

export function setProducts(product) {
  return { type: filterTypes.FILTER_PRODUCTS, product };
}

export function setChannels(channel) {
  return { type: filterTypes.FILTER_CHANNELS, channel };
}

export function setUsers(user) {
  return { type: filterTypes.FILTER_USERS, user };
}

export function clearFilter() {
  return { type: filterTypes.FILTER_CLEAR };
}
