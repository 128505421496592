import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import Alert from '../components/Alert';
import { alertActions } from '../actions/alert-actions';
import { clearFilter } from '../actions/dashboard-filter-actions';
import '../static/fonts/styles.css';
import '../styles/theme.styl';
import Router from '../router';

class App extends React.Component {
  componentDidUpdate(prevProps) {
    const { alert, location, clearMessage, clearFilters } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      clearFilters();
      Modal.destroyAll();
      if (!isEmpty(alert)) {
        clearMessage();
      }
    }
  }

  render() {
    const { alert, user, clearMessage } = this.props;
    return (
      <div>
        {alert.message && (
          <Alert
            animation
            showIcon
            // description=""
            message={alert.message.titulo ? alert.message.titulo : alert.message.description}
            description={alert.message.description}
            type={alert.type}
            clearMessage={clearMessage}
          />
        )}
        <Router user={user} />
      </div>
    );
  }
}
// App.propTypes = propTypes;
const mapStateToProps = (state) => {
  const { login, alert } = state;
  const { loggedIn, roles } = login;
  return {
    user: { roles, loggedIn },
    alert,
    filters: state.filter,
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearMessage() {
    dispatch(alertActions.clear());
  },
  clearFilters() {
    dispatch(clearFilter());
  },
});
const connectedApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
export { connectedApp as App };
