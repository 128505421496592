import { Button, Col, Row } from 'antd';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import './styles/styles.css'
import { AntDatePicker, AntSelect } from '../AntFields/AntFields';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';

const AssigmentFilter = ({supervisorList, setFilter}) => {

    const sendFilter = (item) => {
        let data = {}
        if(item.init_date != undefined){
            data = {
                init_date: item.init_date.format('YYYY-MM-DD')
            }
        }
        if(item.end_date != undefined){
            data = {
                ...data,
                end_date: item.end_date.format('YYYY-MM-DD')
            }
        }
        if(item.supervisor != undefined){
            data = {
                ...data,
                id_assigment_user: item.supervisor
            }
        }
        setFilter(data);
    }
    return (
    <div className="padding-assigment-filter">
        <Formik
        initialValues={{
            init_date: moment(),
            end_date: moment(),
            supervisor: []
        }}
        onSubmit={(values, {setFieldValue}) =>{
            sendFilter(values);
        }}
        >
        {({values, isSubmitting}) => (
            <Form>
                <Row gutter={[8, 8]}>
                    <Col xs={12} sm={6} md={4} lg={4}>
                        <Field
                        allowClear
                        style={{ width: '100%' }}
                        name="init_date"
                        label="Fecha inicio"
                        autoComplete="off"
                        component={AntDatePicker}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4}>
                        <Field
                        allowClear
                        style={{ width: '100%' }}
                        name="end_date"
                        label="Fecha fin"
                        autoComplete="off"
                        component={AntDatePicker}
                        />
                    </Col>
                    {supervisorList ? (
                    <Col xs={12} sm={12} md={4} lg={4}>
                        <Field
                        allowClear
                        style={{ width: '100%' }}
                        name="supervisor"
                        label="Supervisor"
                        component={AntSelect}
                        mode="multiple"
                        placeholder="Selecciona el supervisor"
                        selectOptions={() =>
                            supervisorList.map((item) => (
                                <Option
                                label={item.name + ' ' + item.last_name}
                                key={item.id}
                                value={item.id}
                                >
                                    {item.name + ' ' + item.last_name}
                                </Option>
                            ))
                        }
                        />
                    </Col>
                    ) : (
                    <> </>
                    )}
                </Row>
                <Row justify="end">
                    <Col>
                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">Buscar</Button>
                    </Col>
                </Row>
            </Form>
        )}
        </Formik>
    </div>
    );
};
export default AssigmentFilter;
