import { Button, Checkbox, Col, Row, Spin, Upload } from 'antd';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { AntInput, AntSelect, AntTextArea } from '../AntFields/AntFields';
import { messages } from '../../services/messages';
import { connect } from 'react-redux';
import openNotification from '../Notificaciones';
import useSocket from '../../hooks/useSocket';
import { UploadOutlined } from '@ant-design/icons';
//import io from 'socket.io-client';

const CreateNewMessage = ({ setIsOpen, setRestart, roles, reps, ...props }) => {
  let { socket } = useSocket();
  //let socket = io();
  const [loading, setLoading] = useState(false);
  const [sendRep, setSendRep] = useState(false);
  const [fileList, setFileList] = useState([]);

  const schema = Yup.object().shape({
    subject: Yup.string().required('Requerido'),
    message: Yup.string().required('Requerido')
  });

  const sendMessage = async (items) => {
    console.log(items, 'items')
    let data = {
      subject: items.subject,
      message_content: items.message,
      id_user: props.user.user_id,
      id_channel: items.channel,
      id_main_role: items.role,
    };
    const formData = new FormData();
    formData.append('subject', items.subject);
    formData.append('message_content', items.message);
    formData.append('id_user', props.user.user_id);
    formData.append('id_channel', items.channel);
    formData.append('id_main_role', items.role);

    if (sendRep == true) {
      data = {
        ...data,
        id_rep: items.rep,
      };
      formData.append('id_rep', items.rep);
    }

    if (fileList != undefined) {
        fileList.forEach((file) => {
          console.log(file, 'files');
          formData.append('docto[]', file.originFileObj);
        });
    }
    // for (const pair of formData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }
    try {
      const response = await messages.createMessage(formData);
      setLoading(false);
      openNotification('success', 'Mensaje enviado', true);
      socket.emit('correo', data);
      setIsOpen(false);
      setRestart(true);
      setFileList([]);
      return response;
    } catch (error) {
      setLoading(false);
      openNotification('error', 'Error al enviar' , true);  
      setRestart(true);
      setIsOpen(false);
      setFileList([]);
      return error;
    }

  };


  const checkboxSentToRep = (e) => {
    setSendRep(e.target.checked);
  };

  const handleChange = (info) => {
    let newList = [...info.fileList];
    setFileList(newList);
  };

  const closeModal = () => {
    setFileList([]);
    setIsOpen(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          subject: undefined,
          channel: 1,
          message: undefined,
          role: [1, 2, 8, 15],
          rep: undefined,
        }}
        validationSchema={schema}
        onSubmit={async (values, { setFieldValue, resetForm }) => {
          setLoading(true);
          sendMessage(values);
          resetForm();
        }}
      >
        {({ isSubmitting, resetForm }) => (
          <Spin spinning={loading} tip="Enviando...">
            <Form>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Field
                    type="text"
                    name="subject"
                    size="large"
                    label="Asunto"
                    placeholder="Escribe el asunto"
                    component={AntInput}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Checkbox onChange={(e) => checkboxSentToRep(e)}>
                    Enviar a un rep en especifico
                  </Checkbox>
                  {sendRep ? (
                    <Field
                      name="rep"
                      size="large"
                      label="Rep"
                      placeholder="Selecciona los rep"
                      component={AntSelect}
                      mode="multiple"
                      selectOptions={() =>
                        reps.map((item) => (
                          <Option
                            label={item.name + ' ' + item.last_name}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name + ' ' + item.last_name}
                          </Option>
                        ))
                      }
                    />
                  ) : (
                    <> </>
                  )}
                </Col>
              </Row>

              {/* <Row gutter={[16, 8]}>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Field
                    name="channel"
                    size="large"
                    label="Canal"
                    placeholder="Seleccione un canal"
                    component={AntSelect}
                    selectOptions={() =>
                      props.user.channels.map((item) => (
                        <Option label={item.canal} key={item.id} value={item.id}>
                          {item.canal}
                        </Option>
                      ))
                    }
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Field
                    name="role"
                    size="large"
                    label="Rol"
                    component={AntSelect}
                    mode="multiple"
                    placeholder="Seleccione un rol"
                    selectOptions={() =>
                      roles.map((item) => (
                        <Option label={item.main_roles} key={item.id} value={item.id}>
                          {item.main_roles}
                        </Option>
                      ))
                    }
                  />
                </Col>
              </Row> */}
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Field
                    type="text"
                    name="message"
                    size="large"
                    label="Mensaje"
                    component={AntTextArea}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Upload beforeUpload={() => false} onChange={handleChange} fileList={[...fileList]}>
                    <Button icon={<UploadOutlined />}>Selecciona un archivo</Button>
                  </Upload>
                  {/* <Button
                  type="primary"
                  onClick={uploadFile}
                  loading={upload}
                  disabled={fileList.length === 0}
                  >
                    {upload ? "Subiendo" : "Subir"}
                  </Button>  */}
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Button danger onClick={() => closeModal()}>
                    Cancelar mensaje
                  </Button>
                </Col>
                <Col className="display-justify-end" xs={12} sm={6} md={6} lg={6}>
                  <Button type="primary" htmlType="submit">
                    Enviar mensaje
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        )}
      </Formik>
    </div>
  );
};
function mapStateToProps(state) {
  const { user } = state.login;
  return {
    user: state.login.user,
  };
}
export default connect(mapStateToProps)(CreateNewMessage);
