import { notification } from 'antd';

const openNotification = (type, message, description, bottom = null) => {
  notification.config({
    duration: 10,
  });
  if (bottom != null) {
    notification.config({
      bottom: 120,
      placement: 'bottomRight',
      duration: 6,
    });
  }
  notification[type]({
    message,
    description,
  });
};
export default openNotification;
